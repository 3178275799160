import { IGenericHash } from "../../../../types/AppWeb";
import React from "react";

export interface IWorkAttributeRowProps {
  attribute: IGenericHash;
  updateAttributeName: any;
  updateAttributeValue: any;
  deleteAttribute(): void;
}

const WorkAttributeRow = (props: IWorkAttributeRowProps): JSX.Element => {
  const {
    // attribute,
    // updateAttributeName,
    // updateAttributeValue,
    deleteAttribute,
  } = props;
  return (
    <div className={"attribute-row"}>
      {/*<InputGroup
        id={`attribute-name-${attribute.id}`}
        value={attribute.name}
        onChange={updateAttributeName}
        key={`attribute-name-${attribute.id}`}
        className={"attribute-name"}
        disabled={false}
      />
      <InputGroup
        id={`attribute-value-${attribute.id}`}
        value={attribute.value}
        onChange={updateAttributeValue}
        key={`attribute-value-${attribute.id}`}
        className={"attribute-value"}
        disabled={false}
      />*/}
      <p>Attribute redacted - not worth supporting for now</p>
      <button onClick={deleteAttribute}>Delete</button>
    </div>
  );
};

export default WorkAttributeRow;
