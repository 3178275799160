import React from "react";
import { NoopMutation } from "../../gql/NoopQueries";
import { UserSelfQuery } from "../../gql/UserQueries";
import { EGqlFormFieldType, EGqlFormPageType } from "../../types/AppEnums";
import Sidebar, { AccountSidebarLinks } from "../components/Sidebar";
import GqlForm from "../meta/GqlForm";

const formSpecs = {
  display: {
    readonly: true,
  },
  fields: [
    {
      disabled: true,
      key: "email",
      label: "Email",
      type: EGqlFormFieldType.Text,
    },
  ],
  gql: {
    mutation: NoopMutation,
    pageType: EGqlFormPageType.Edit,
    query: UserSelfQuery,
  },
  model: {
    name: "userSelf",
  },
};

const UserEditSelfTitle = "Your User";

const UserEditSelf = (props: any): JSX.Element => {
  const { throwForTests } = props;

  return (
    <div className="o4-layout-page account-page">
      <Sidebar links={AccountSidebarLinks} activeKey="acct-sidebar-user" />

      <div className="o4-layout-main">
        <h1>{UserEditSelfTitle}</h1>

        <GqlForm formSpecs={formSpecs} throwForTests={!!throwForTests} />
      </div>
    </div>
  );
};

export { UserEditSelfTitle };

export default UserEditSelf;
