import { IWithNavProps } from "../../types/AppWeb";
import React from "react";
import { JobQuery, UpdateJobQuery } from "../../gql/JobQueries";
import { BrowserJs } from "../../lib/BrowserJs";
import { jobsListPg, navFnFor } from "../../lib/UrlHelper";
import { EGqlFormFieldType, EGqlFormPageType } from "../../types/AppEnums";
import Crumbs from "../components/Crumbs";
import GqlForm from "../meta/GqlForm";

const formSpecs = {
  fields: [
    {
      disabled: true,
      key: "id",
      label: "ID",
      type: EGqlFormFieldType.Text,
    },
    {
      key: "status",
      label: "Status",
      required: true,
      type: EGqlFormFieldType.Text,
    },
    {
      key: "shipBy",
      label: "Ship By",
      required: true,
      type: EGqlFormFieldType.Datetime,
    },
    {
      dataKey: "billingAddress",
      key: "billingAddressId",
      label: "Billing Address",
      required: true,
      type: EGqlFormFieldType.Address,
    },
    {
      dataKey: "shippingAddress",
      key: "shippingAddressId",
      label: "Shipping Address",
      required: true,
      type: EGqlFormFieldType.Address,
    },
  ],
  gql: {
    mutation: UpdateJobQuery,
    pageType: EGqlFormPageType.Edit,
    query: JobQuery,
  },
  model: {
    name: "job",
  },
};

const JobEditTitle = "Job Page";

const JobEdit = (props: IWithNavProps): JSX.Element => {
  const id = props.match.params.id;
  BrowserJs.setTitle(`Job #${id}`);

  return (
    <div className={"o4-page-without-sidebar job-page"}>
      <Crumbs location={props.location} />

      <h1>{JobEditTitle}</h1>

      <GqlForm
        formSpecs={formSpecs}
        queryVariables={{ id }}
        onMutationCompleted={navFnFor(jobsListPg())}
      />
    </div>
  );
};

export { JobEditTitle };

export default JobEdit;
