import { gql } from "@apollo/client";

// Returns "currently signed in" user
export const UserSelfQuery = gql`
  {
    userSelf {
      id
      email
    }
  }
`;

export const UserQuery = gql`
  query user($id: String!) {
    user(id: $id) {
      id
      email
    }
  }
`;

export const UserMutation = gql`
  mutation updateUser($id: String!, $email: String!) {
    updateUser(id: $id, email: $email) {
      id
      email
    }
  }
`;

export const ListUsersGql = gql`
  {
    users {
      id
      email
    }
  }
`;

export const CreateUserQuery = gql`
  mutation createUser(
    $email: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    createUser(
      email: $email
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      id
      email
    }
  }
`;

export const DeleteUserQuery = gql`
  mutation deleteUser($id: String!) {
    deleteUser(id: $id) {
      id
      email
    }
  }
`;
