import React, { useState } from "react";
import { UserMutation, UserQuery } from "../../gql/UserQueries";
import { editUserPg, listUsersPg, navFnFor } from "../../lib/UrlHelper";
import { EGqlFormFieldType, EGqlFormPageType } from "../../types/AppEnums";
import Sidebar, { AccountSidebarLinks } from "../components/Sidebar";
import GqlForm from "../meta/GqlForm";
import { Breadcrumbs, Link } from "@material-ui/core";

const formSpecs = {
  display: {
    readonly: false,
  },
  fields: [
    {
      disabled: true,
      key: "id",
      label: "ID",
      type: EGqlFormFieldType.Text,
    },
    {
      disabled: false,
      key: "email",
      label: "email",
      type: EGqlFormFieldType.Text,
    },
  ],
  gql: {
    mutation: UserMutation,
    pageType: EGqlFormPageType.Edit,
    query: UserQuery,
  },
  model: {
    name: "user",
  },
};

const UserEditTitle = "User";

const buildOnCompleted = (setEmail: any) => (resp: any) => {
  if (resp?.user?.email) {
    setEmail(resp?.user?.email);
  }
};

const generateUserDisplay = (email: string, id: string): string => {
  return email === "" ? id : email;
};

const generateTitle = (email: string): string => {
  return `Edit ${email === "" ? "User" : email}`;
};

const UserEdit = (props: any): JSX.Element => {
  const { id } = props.match.params;

  const [email, setEmail] = useState("");

  return (
    <div className="o4-layout-page account-page">
      <Sidebar links={AccountSidebarLinks} activeKey="acct-sidebar-user" />

      <div className="o4-layout-main">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href={listUsersPg()}>
            Users
          </Link>
          <Link underline="hover" color="inherit" href={editUserPg(id)}>
            {generateUserDisplay(email, id)}
          </Link>
        </Breadcrumbs>

        <h1>{generateTitle(email)}</h1>

        <GqlForm
          formSpecs={formSpecs}
          queryVariables={{ id }}
          onQueryCompleted={buildOnCompleted(setEmail)}
          onMutationCompleted={navFnFor(listUsersPg())}
        />
      </div>
    </div>
  );
};

export { UserEditTitle };

export default UserEdit;
