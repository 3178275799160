import { IGenericHash, IRefetchQuery } from "../../types/AppWeb";
import React from "react";
import { DeleteUserQuery, ListUsersGql } from "../../gql/UserQueries";
import { editUserPg, listUsersPg, newUserPg } from "../../lib/UrlHelper";
import { EGqlFormFieldType } from "../../types/AppEnums";
import Sidebar, { AccountSidebarLinks } from "../components/Sidebar";
import GqlList from "../meta/GqlList";
import GqlListRow from "../meta/GqlListRow";
import { Breadcrumbs, Link } from "@material-ui/core";

const listSpecs = {
  display: {
    goToItem: (rowData: IGenericHash): string => {
      const { id } = rowData;
      return editUserPg(id);
    },
    rowComponent: GqlListRow,
    classes: "o4-editable",
  },
  fields: [
    {
      key: "email",
      label: "Email",
      type: EGqlFormFieldType.Text,
    },
  ],
  gql: {
    buildDeletionVariables: (rowData: IGenericHash): IGenericHash => ({
      id: rowData.id,
    }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    buildRefetchQueries: (rowData: IGenericHash): IRefetchQuery[] => [
      { query: ListUsersGql },
    ],
    deleteItemQuery: DeleteUserQuery,
    extractListData: (qryData: IGenericHash): IGenericHash[] => {
      if (qryData && qryData.users) {
        return qryData.users;
      }
      return [];
    },
    listQuery: ListUsersGql,
  },
  model: {
    name: "users",
  },
};

const UserListTitle = "Your Team";

const UserList = (): JSX.Element => {
  return (
    <div className="o4-layout-page account-page">
      <Sidebar links={AccountSidebarLinks} activeKey="acct-sidebar-users" />

      <div className="o4-layout-main">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href={listUsersPg()}>
            Users
          </Link>
        </Breadcrumbs>

        <h1>{UserListTitle}</h1>

        <GqlList
          listSpecs={listSpecs}
          listVariables={{}}
          newFormPage={newUserPg()}
        />
      </div>
    </div>
  );
};

export { UserListTitle };

export default UserList;
