import React from "react";
import { ShipmentQuery, UpdateShipmentQuery } from "../../gql/ShipmentQueries";
import { navFnFor, shipmentsForJobPg } from "../../lib/UrlHelper";
import { EGqlFormFieldType, EGqlFormPageType } from "../../types/AppEnums";
import Crumbs from "../components/Crumbs";
import GqlForm from "../meta/GqlForm";

const formSpecs = {
  fields: [
    {
      disabled: true,
      key: "id",
      label: "ID",
      type: EGqlFormFieldType.Text,
    },
    {
      key: "method",
      label: "Method",
      required: true,
      type: EGqlFormFieldType.Text,
    },
    {
      dataKey: "shippingAddress",
      key: "shippingAddressId",
      label: "Shipping Address",
      required: true,
      type: EGqlFormFieldType.Address,
    },
  ],
  gql: {
    mutation: UpdateShipmentQuery,
    pageType: EGqlFormPageType.Edit,
    query: ShipmentQuery,
  },
  model: {
    name: "shipment",
  },
};

const ShipmentEditTitle = "Shipment";

const ShipmentEdit = (props: any): JSX.Element => {
  const { id: jobId, shipmentId } = props.match.params;

  return (
    <div className="o4-page-without-sidebar">
      <Crumbs location={props.location} />

      <h1>{ShipmentEditTitle}</h1>

      <GqlForm
        formSpecs={formSpecs}
        queryVariables={{ id: shipmentId }}
        onMutationCompleted={navFnFor(shipmentsForJobPg(jobId))}
        throwForTests={!!props.throwForTests}
      />
    </div>
  );
};

export { ShipmentEditTitle };

export default ShipmentEdit;
