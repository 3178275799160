import { IGenericHash, IRefetchQuery } from "../../types/AppWeb";
import React from "react";
import {
  DeleteWorkItemQuery,
  JobWithItemsQuery,
} from "../../gql/WorkItemQueries";
import { editItemForJobPg, newItemForJobPg } from "../../lib/UrlHelper";
import { EGqlFormFieldType } from "../../types/AppEnums";
import Crumbs from "../components/Crumbs";
import GqlList from "../meta/GqlList";
import GqlListRow from "../meta/GqlListRow";

interface IItemsProp {
  match: any;
  history: any;
  location: any;
}

const listSpecs = {
  display: {
    goToItem: (data: any) => {
      const { jobId, id: itemId } = data;
      return editItemForJobPg(jobId, itemId);
    },
    rowComponent: GqlListRow,
  },
  fields: [
    {
      key: "quantity",
      label: "Quantity",
      type: EGqlFormFieldType.Text,
    },
    {
      key: "productName",
      label: "Product",
      type: EGqlFormFieldType.Text,
    },
    {
      key: EGqlFormFieldType.WorkAttributes,
      label: "Description",
      type: EGqlFormFieldType.WorkAttributes,
    },
    {
      key: "total",
      label: "Total",
      type: EGqlFormFieldType.Text,
    },
  ],
  gql: {
    buildDeletionVariables: (rowData: IGenericHash): IGenericHash => ({
      id: rowData.id,
    }),
    buildRefetchQueries: (rowData: IGenericHash): IRefetchQuery[] => [
      { query: JobWithItemsQuery, variables: { id: rowData.jobId } },
    ],
    deleteItemQuery: DeleteWorkItemQuery,
    extractListData: (qryData: IGenericHash): IGenericHash[] => {
      if (qryData && qryData.job && qryData.job.workItems) {
        return qryData.job.workItems;
      }
      return [];
    },
    listQuery: JobWithItemsQuery,
  },
  model: {
    name: "item",
  },
};

const ItemListTitle = "Items";

const ItemList = (props: IItemsProp): JSX.Element => {
  const id = props.match.params.id;
  return (
    <div className={"o4-page-without-sidebar items-page"}>
      <Crumbs location={props.location} />
      <h1>{ItemListTitle}</h1>
      <GqlList
        listSpecs={listSpecs}
        listVariables={{ id }}
        newFormPage={newItemForJobPg(id)}
      />
    </div>
  );
};

export { ItemListTitle };

export default ItemList;
