import { IGenericHash, IRefetchQuery } from "../../types/AppWeb";
import React from "react";
import { Button } from "@material-ui/core";
import { AllJobsQuery, DeleteJobQuery } from "../../gql/JobQueries";
import { BrowserJs } from "../../lib/BrowserJs";
import {
  editJobPg,
  itemsForJobPg,
  newJobPg,
  shipmentsForJobPg,
} from "../../lib/UrlHelper";
import { EGqlFormFieldType } from "../../types/AppEnums";
import Crumbs from "../components/Crumbs";
import GqlList from "../meta/GqlList";
import GqlListRow from "../meta/GqlListRow";

const buildExtraActions = (data: IGenericHash): JSX.Element => {
  const { id } = data;
  return (
    <>
      <Button href={itemsForJobPg(id)} variant="contained" color="primary">
        Items
      </Button>
      <Button href={shipmentsForJobPg(id)} variant="contained" color="primary">
        Shipments
      </Button>
    </>
  );
};
buildExtraActions.displayName = "BuildExtraActions";

const listSpecs = {
  display: {
    buildExtraActions,
    goToItem: (rowData: IGenericHash) => editJobPg(rowData.id),
    rowComponent: GqlListRow,
  },
  fields: [
    {
      key: "billingAddress",
      label: "Billing Name",
      type: EGqlFormFieldType.Address,
    },
    {
      key: "status",
      label: "Status",
      type: EGqlFormFieldType.Text,
    },
    {
      key: "shipBy",
      label: "Ship By",
      type: EGqlFormFieldType.Datetime,
    },
  ],
  gql: {
    buildDeletionVariables: (rowData: IGenericHash): IGenericHash => ({
      id: rowData.id,
    }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    buildRefetchQueries: (rowData: IGenericHash): IRefetchQuery[] => [
      { query: AllJobsQuery },
    ],
    deleteItemQuery: DeleteJobQuery,
    listQuery: AllJobsQuery,
  },
  model: {
    name: "job",
  },
};

const JobListTitle = "Jobs";

const JobList = (props: any): JSX.Element => {
  BrowserJs.setTitle(JobListTitle);
  return (
    <div className="o4-page-without-sidebar live-jobs">
      <Crumbs location={props.location} />
      <h1>{JobListTitle}</h1>
      <GqlList listSpecs={listSpecs} newFormPage={newJobPg()} />
    </div>
  );
};

export { JobListTitle };

export default JobList;
