import React from "react";

export const ForbiddenText = "Please log in.";

export default function Forbidden(): JSX.Element {
  return (
    <div className="o4-page-without-sidebar">
      <p className="o4-first-element-in-body">{ForbiddenText}</p>
    </div>
  );
}
