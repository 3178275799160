import { IWithNavProps } from "../../types/AppWeb";
import React from "react";
import { CreateItemQuery, JobWithItemsQuery } from "../../gql/WorkItemQueries";
import { itemsForJobPg, navFnFor } from "../../lib/UrlHelper";
import { EGqlFormFieldType, EGqlFormPageType } from "../../types/AppEnums";
import Crumbs from "../components/Crumbs";
import GqlForm from "../meta/GqlForm";
import ItemUtil from "./ItemUtil";

const formSpecs = {
  fields: [
    {
      key: "quantity",
      label: "Quantity",
      required: true,
      type: EGqlFormFieldType.Text,
    },
    {
      key: "productName",
      label: "Product",
      required: true,
      type: EGqlFormFieldType.Text,
    },
    {
      key: "total",
      label: "Total",
      required: true,
      type: EGqlFormFieldType.Text,
    },
    {
      key: "workAttributes",
      label: "Work Attributes",
      required: true,
      type: EGqlFormFieldType.WorkAttributes,
    },
  ],
  gql: {
    mutation: CreateItemQuery,
    pageType: EGqlFormPageType.Create,
  },
  model: {
    name: "workItem",
  },
};

const ItemNewTitle = "Item";

const ItemNew = (props: IWithNavProps): JSX.Element => {
  const { id: jobId } = props.match.params;
  const refetchQueries = [
    { query: JobWithItemsQuery, variables: { id: jobId } },
  ];

  return (
    <div className="o4-page-without-sidebar">
      <Crumbs location={props.location} />

      <h1>{ItemNewTitle}</h1>

      <GqlForm
        formSpecs={formSpecs}
        onMutationCompleted={navFnFor(itemsForJobPg(jobId))}
        uiToMutationFn={ItemUtil.uiToMutationFn}
        mutationVariables={{ jobId }}
        refetchQueries={refetchQueries}
      />
    </div>
  );
};

export { ItemNewTitle };

export default ItemNew;
