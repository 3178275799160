import React from "react";
import {
  OrganizationQuery,
  UpdateOrganizationQuery,
} from "../../gql/OrganizationQueries";
import { EGqlFormFieldType, EGqlFormPageType } from "../../types/AppEnums";
import Sidebar, { AccountSidebarLinks } from "../components/Sidebar";
import GqlForm from "../meta/GqlForm";

const formSpecs = {
  fields: [
    {
      disabled: true,
      key: "id",
      label: "ID",
      type: EGqlFormFieldType.Text,
    },
    {
      key: "name",
      label: "Name",
      required: true,
      type: EGqlFormFieldType.Text,
    },
  ],
  gql: {
    mutation: UpdateOrganizationQuery,
    pageType: EGqlFormPageType.Edit,
    query: OrganizationQuery,
  },
  model: {
    name: "organization",
  },
};

const OrganizationEditTitle = "Your Organization";

const OrganizationEdit = (props: any): JSX.Element => {
  const { throwForTests } = props;

  return (
    <div className="o4-layout-page account-page">
      <Sidebar
        links={AccountSidebarLinks}
        activeKey="acct-sidebar-organization"
      />

      <div className="o4-layout-main">
        {/* eslint-disable-next-line */}
        <h1 role="heading">{OrganizationEditTitle}</h1>

        <GqlForm formSpecs={formSpecs} throwForTests={!!throwForTests} />
      </div>
    </div>
  );
};

export { OrganizationEditTitle };

export default OrganizationEdit;
