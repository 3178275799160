import {
  AppBar,
  createStyles,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Assignment from "@material-ui/icons/Assignment";
import Home from "@material-ui/icons/Home";
import React from "react";
import { editUserSelfPg } from "../lib/UrlHelper";

import "./AppNavbar.scss";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  })
);

const AppNavbar = (props: any): JSX.Element => {
  const { auth0Module } = props;
  const { isAuthenticated, loginWithRedirect, logout } = auth0Module() as any;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): any => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar variant="dense">
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              href="/"
            >
              <Home />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Home
            </Typography>
            <div>
              {isAuthenticated && (
                <IconButton
                  aria-label="jobs"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                  href="/jobs"
                >
                  <Assignment />
                </IconButton>
              )}
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={handleMenu}
              >
                <AccountCircle />
              </IconButton>
              {/* 6/20/20 - Removed keepMounted because caused test render problems. Doesn't seem
               critical and I didn't think it was worth troubleshooting tests. */}
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
              >
                {!isAuthenticated && (
                  <MenuItem onClick={() => loginWithRedirect({})}>
                    Login
                  </MenuItem>
                )}
                {isAuthenticated && (
                  <MenuItem>
                    <Link href={editUserSelfPg()}>My account</Link>
                  </MenuItem>
                )}
                {isAuthenticated && (
                  <MenuItem onClick={() => logout()}>Log out</MenuItem>
                )}
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    </>
  );
};

export default AppNavbar;
