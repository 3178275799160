import { useMutation } from "@apollo/client";
import { IAddress, IGenericHash } from "../../../../types/AppWeb";
import React from "react";
import {
  AddressesQuery,
  UpdateAddressQuery,
} from "../../../../gql/AddressQueries";
import { GqlHookResp } from "../../../../lib/GqlHookResp";
import AddressPres from "./AddressPres";

interface IAddressProps {
  address: IAddress | null; // todo can tighten
  visible: boolean;
  goCancel(): void;
  onSuccess(data: IAddress): void;
}

const Address = (props: IAddressProps): JSX.Element => {
  const { address, goCancel, visible, onSuccess } = props;

  const onCompleted = (response: IGenericHash) => {
    const { createAddress } = response;
    onSuccess(createAddress);
  };

  const mutationProps = {
    onCompleted,
    refetchQueries: [{ query: AddressesQuery }],
    onError: GqlHookResp.defaultErrorHandler,
  };
  const [mutate, rawMutResponse] = useMutation(
    UpdateAddressQuery,
    mutationProps
  );
  const mutResp = GqlHookResp.fromRawResponse(rawMutResponse);

  if (!visible) {
    return <span />;
  }
  if (address === null) {
    throw new Error("Address is null");
  }
  if (mutResp.canHandle()) {
    return mutResp.handle();
  }

  const saveFn = (addressParams: IGenericHash) => {
    const sanitizedAddress = {
      firstName: addressParams.firstName,
      id: addressParams.id,
      lastName: addressParams.lastName,
    };
    mutate({ variables: sanitizedAddress });
  };

  return <AddressPres address={address} onSave={saveFn} goCancel={goCancel} />;
};

export default Address;
