import React, { ComponentClass } from "react";
import { Route } from "react-router";

const renderFn = (Component: ComponentClass, auth0ModuleInstance: any) => {
  const theFunc = (props: any): JSX.Element => {
    // Loading should never be 'true', but we handle it defensively
    const { loading, isAuthenticated } = auth0ModuleInstance;

    if (isAuthenticated) {
      return <Component {...props} />;
    } else if (loading) {
      return (
        <div className="o4-page-without-sidebar">
          <p className="o4-first-element-in-body">Loading...</p>
        </div>
      );
    } else if (!isAuthenticated) {
      return (
        <div className="o4-page-without-sidebar">
          <p className="o4-first-element-in-body">
            Please login to use this page.
          </p>
        </div>
      );
    }

    // This should be impossible b/c we handle for 'isAuthenticated' and for '!isAuthenticated'
    return (
      <div className="o4-page-without-sidebar">
        <p className="o4-first-element-in-body">
          We encountered an unexpected error during login.
        </p>
      </div>
    );
  };

  return theFunc;
};

export default function PrivateRoute(props: any): JSX.Element {
  const { component, auth0Module, ...rest } = props;
  const auth0ModuleInstance = auth0Module(); // Avoid a hook error - we can't use a hook in a render fn
  return <Route {...rest} render={renderFn(component, auth0ModuleInstance)} />;
}
