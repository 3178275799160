import React from "react";

export default function Expired(): JSX.Element {
  return (
    <div className="o4-page-without-sidebar">
      <p className="o4-first-element-in-body">
        Your session has expired, please log back in.
      </p>
    </div>
  );
}
