import { IWithNavProps } from "../../types/AppWeb";
import React from "react";
import {
  CreateShipmentQuery,
  JobWithShipmentsQuery,
} from "../../gql/ShipmentQueries";
import { navFnFor, shipmentsForJobPg } from "../../lib/UrlHelper";
import { EGqlFormFieldType, EGqlFormPageType } from "../../types/AppEnums";
import Crumbs from "../components/Crumbs";
import GqlForm from "../meta/GqlForm";

const formSpecs = {
  fields: [
    {
      key: "method",
      label: "Method",
      required: true,
      type: EGqlFormFieldType.Text,
    },
    {
      dataKey: "shippingAddress",
      key: "shippingAddressId",
      label: "Shipping Address",
      required: true,
      type: EGqlFormFieldType.Address,
    },
  ],
  gql: {
    mutation: CreateShipmentQuery,
    pageType: EGqlFormPageType.Create,
  },
  model: {
    name: "shipment",
  },
};

const ShipmentNewTitle = "New Shipment";

const ShipmentNew = (props: IWithNavProps): JSX.Element => {
  const { id: jobId } = props.match.params;
  const refetchQueries = [
    { query: JobWithShipmentsQuery, variables: { id: jobId } },
  ];

  return (
    <div className="o4-page-without-sidebar">
      <Crumbs location={props.location} />

      <h1>{ShipmentNewTitle}</h1>

      <GqlForm
        formSpecs={formSpecs}
        onMutationCompleted={navFnFor(shipmentsForJobPg(jobId))}
        mutationVariables={{ jobId }}
        refetchQueries={refetchQueries}
      />
    </div>
  );
};

export { ShipmentNewTitle };

export default ShipmentNew;
