import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { AppConf } from "../conf/AppConf";
import { Konsole } from "./dev/Konsole";
import ApolloLinkTimeout from "apollo-link-timeout";

export class ApolloAppClient {
  /**
   * Creates an ApolloClient
   * @param auth Holds the current auth state, whether logged in or out
   * @returns ApolloClient
   */
  public static create = (
    isAuthenticated: any,
    authBundle: any
  ): ApolloClient<any> => {
    // HTTP auth
    // todo handle HTTP 401s
    const authLink = new ApolloLink((operation, forward) => {
      const token = authBundle && authBundle.__raw; // Fetch on every request

      // This headers flag might be creating a totally new set of headers
      operation.setContext({
        headers: {
          authorization: token ? `Bearer ${token}` : "",
        },
      });

      if (!isAuthenticated) {
        Konsole.info(
          "ApolloAppClient:authLink: skipping forward, not yet authenticated"
        );
        return null;
      } else if (forward) {
        return forward(operation);
      }

      Konsole.info("ApolloAppClient:authLink: forward not defined");
      return null;
    });

    // Error handling
    const errorHandlerLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          // tslint:disable-next-line
          Konsole.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      }

      // tslint:disable-next-line
      if (networkError) {
        Konsole.error(`[Network error]: ${networkError}`);
      }
    });

    const httpLink = new HttpLink({ uri: AppConf.gqlUrl });

    const timeoutLink = new ApolloLinkTimeout(AppConf.apolloTimeoutMs);

    const retryLink = new RetryLink({
      delay: {
        initial: 300,
        max: Infinity,
        jitter: true,
      },
      attempts: {
        // A lower "max" may cause components to re-render, thus resetting the retryLink and cause Thundering Herd
        max: AppConf.numGqlRetries,
        retryIf: (error, _operation) => !!error, // eslint-disable-line
      },
    });

    return new ApolloClient({
      cache: new InMemoryCache(),
      link: from([
        errorHandlerLink,
        authLink,
        // @ts-ignore
        retryLink,
        // @ts-ignore
        timeoutLink,
        httpLink,
      ]),
    });
  };
}
