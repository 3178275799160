import { useQuery } from "@apollo/client";
import {
  Button,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@material-ui/core";
import { Konsole } from "../../lib/dev/Konsole";
import { IGqlListFieldSpec, IGqlListProps } from "../../types/GqlList";
import { IGenericHash } from "../../types/AppWeb";
import React, { useState } from "react";
import AppHistory from "../../conf/AppHistory";
import { GqlHookResp } from "../../lib/GqlHookResp";
import { Redirect } from "react-router-dom";
import { ErrorBoundary } from "./ErrorBoundary";
import { GqlErrorDisplay } from "./GqlErrorDisplay";

const GqlList = (props: IGqlListProps): JSX.Element => {
  const { listSpecs, listVariables, newFormPage } = props;
  const { fields, gql, display } = listSpecs;
  const { listQuery, extractListData } = gql;
  const { rowComponent, classes } = display;

  const [alert, setAlert] = useState({} as any);
  const [userErrors, setUserErrors] = useState([] as any);

  const errorDisplayOpts = {
    alert,
    setAlert,
    userErrors,
    setUserErrors,
  };

  const gotoCreation = (): any => {
    AppHistory.push(newFormPage);
  };
  const [isRedirecting, setIsRedirecting] = useState(false);

  const normalListVars = listVariables || {};
  const classesStr = classes || "";

  const qryParams = { variables: normalListVars };
  const rawQryResponse = useQuery(listQuery, qryParams);
  const qryResp = GqlHookResp.fromRawResponse(rawQryResponse);

  if (isRedirecting) {
    return <Redirect to="/expired" />;
  }
  if (qryResp.hasExpiredJwt()) {
    Konsole.info("GqlList: expired JWT, not handled");
    setIsRedirecting(true);
  }
  if (qryResp.canHandle()) {
    return qryResp.handle();
  }

  // todo handle undefined data
  const { data: rawData } = rawQryResponse;
  if (rawData === undefined || rawData === null) {
    return <div>Data not loaded yet.</div>;
  }
  const data = extractListData
    ? extractListData(rawData)
    : (Object.values(rawData)[0] as any);
  // Handle empty data
  if (data === undefined) {
    return <div>Data not loaded yet.</div>;
  }

  return (
    <ErrorBoundary>
      <GqlErrorDisplay {...errorDisplayOpts} />

      <TableContainer component={Paper}>
        <Table className={`o4-table ${classesStr}`}>
          <TableHead>
            <TableRow>
              {fields &&
                fields.map((fieldSpec: IGqlListFieldSpec) => {
                  return (
                    <TableCell key={fieldSpec.key}>{fieldSpec.label}</TableCell>
                  );
                })}
              <TableCell key="actions">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((datum: IGenericHash, index: number) => {
                const rowProps = {
                  data: datum,
                  key: index,
                  listSpecs,
                  errorDisplayOpts,
                };
                return React.createElement(rowComponent, rowProps);
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <br />
      <Button onClick={gotoCreation} variant="contained" color="primary">
        Create
      </Button>
    </ErrorBoundary>
  );
};

export default GqlList;
