import { gql } from "@apollo/client";

export const AllJobsQuery = gql`
  {
    jobs {
      id
      shipBy
      status
      billingAddress {
        id
        firstName
        lastName
      }
    }
  }
`;

export const JobQuery = gql`
  query job($id: String!) {
    job(id: $id) {
      id
      shipBy
      status
      updatedAt
      insertedAt
      billingAddress {
        id
        firstName
        lastName
      }
      shippingAddress {
        id
        firstName
        lastName
      }
    }
  }
`;

export const CreateJobQuery = gql`
  mutation createJob(
    $shipBy: String
    $status: String
    $billingAddressId: String
    $shippingAddressId: String
  ) {
    createJob(
      shipBy: $shipBy
      status: $status
      billingAddressId: $billingAddressId
      shippingAddressId: $shippingAddressId
    ) {
      id
      shipBy
      status
      updatedAt
      insertedAt
      billingAddress {
        id
        firstName
        lastName
      }
      shippingAddress {
        id
        firstName
        lastName
      }
    }
  }
`;

export const UpdateJobQuery = gql`
  mutation updateJob(
    $id: String!
    $shipBy: String
    $status: String
    $billingAddressId: String
    $shippingAddressId: String
  ) {
    updateJob(
      id: $id
      shipBy: $shipBy
      status: $status
      billingAddressId: $billingAddressId
      shippingAddressId: $shippingAddressId
    ) {
      id
      shipBy
      status
      updatedAt
      insertedAt
      billingAddress {
        id
        firstName
        lastName
      }
      shippingAddress {
        id
        firstName
        lastName
      }
    }
  }
`;

export const DeleteJobQuery = gql`
  mutation deleteJob($id: String!) {
    deleteJob(id: $id) {
      id
      shipBy
      status
      updatedAt
      insertedAt
    }
  }
`;
