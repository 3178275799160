import { TGqlFieldSpec } from "../../../types/FieldTypes";
import { IGqlFormFieldSpec } from "../../../types/GqlForm";
import { IGenericHash } from "../../../types/AppWeb";
import { ChangeEvent } from "react";
import DynamicForm from "../DynamicForm";

export const textDatumToLabel = (
  datum: IGenericHash,
  fieldSpec: TGqlFieldSpec
): string => {
  return datum[fieldSpec.key];
};

export const buildTextChangeFn =
  (spec: IGqlFormFieldSpec, origThis: DynamicForm) =>
  (event: ChangeEvent<HTMLInputElement>) => {
    const newData = { ...origThis.state.formData } as any;
    newData[spec.key] = event.currentTarget.value;
    origThis.setState({ formData: newData });
  };
