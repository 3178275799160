import { TGqlFieldSpec } from "../../../types/FieldTypes";
import { IGqlFormFieldSpec } from "../../../types/GqlForm";
import { IGenericHash } from "../../../types/AppWeb";
import DateFormatter from "../../../lib/formatters/DateFormatter";
import DynamicForm from "../DynamicForm";

export const datetimeToLabel = (
  datum: IGenericHash,
  fieldSpec: TGqlFieldSpec
): string => {
  return DateFormatter.friendlyDateTime(datum[fieldSpec.key]);
};

export const buildDateTimeChangeFn =
  (spec: IGqlFormFieldSpec, origThis: DynamicForm) => (date: IGenericHash) => {
    const { key } = spec;
    const newData = { ...origThis.state.formData } as any;
    newData[key] = date.toISOString();

    origThis.setState({ formData: newData });
  };
