export const buildShowAlertHandler =
  (setAlert: any): any =>
  (message: string, severity: string) => {
    setAlert({
      isOpen: true,
      message,
      severity: severity || "success",
    });
  };

export const buildHideAlertHandler =
  (existingAlert: any, setAlert: any): any =>
  () => {
    setAlert({
      message: existingAlert.message,
      severity: existingAlert.severity,
      isOpen: false,
    });
  };
