import { useMutation, useQuery } from "@apollo/client";
import { Redirect } from "react-router-dom";
import { Konsole } from "../../lib/dev/Konsole";
import {
  IGqlFormProps,
  IGqlFormSpecs,
  IGqlQueryProps,
  TConnectedSaveFn,
} from "../../types/GqlForm";
import { IGenericHash } from "../../types/AppWeb";
import React, { useState } from "react";
import { NoopQuery } from "../../gql/NoopQueries";
import { GqlHookResp } from "../../lib/GqlHookResp";
import { EGqlFormPageType } from "../../types/AppEnums";
import DynamicForm from "./DynamicForm";
import { ErrorBoundary } from "./ErrorBoundary";
import "./GqlForm.scss";
import { noop } from "lodash";
import { buildGqlUiErrorHandler, buildMutationProps } from "./GqlHandlers";
import { GqlErrorDisplay, IGqlErrorDisplay } from "./GqlErrorDisplay";

const buildQueryProps = (
  props: IGqlFormProps,
  errorDisplayOpts: IGqlErrorDisplay
): IGqlQueryProps => {
  const { queryVariables, formSpecs, onQueryError, onQueryCompleted } = props;

  const errorHandler = buildGqlUiErrorHandler(
    { onQueryError },
    errorDisplayOpts
  );
  const completeHandler = onQueryCompleted || noop;

  if (shouldQuery(formSpecs)) {
    return {
      variables: queryVariables || {},
      onError: errorHandler,
      onCompleted: completeHandler,
    };
  } else {
    return {
      skip: true,
      variables: {},
      onError: errorHandler,
      onCompleted: completeHandler,
    };
  }
};

const connectSaveFn = (props: IGqlFormProps, mutate: any): TConnectedSaveFn => {
  const { uiToMutationFn, mutationVariables } = props;
  return (rawFormData: IGenericHash) => {
    // Use the transform function, if given
    const gqlMutationVariables = uiToMutationFn
      ? uiToMutationFn(rawFormData)
      : rawFormData;
    // Inject mutationVariables, overriding the transformed variables
    const variablesWithOverrides = {
      ...gqlMutationVariables,
      ...mutationVariables,
    };

    // todo: handle promises
    // todo: add type to mutate
    mutate({ variables: variablesWithOverrides });
  };
};

const normalizeMutationResponse = (
  props: IGqlFormProps,
  mutResp: GqlHookResp
): IGenericHash => {
  const { mutationToUiFn, formSpecs } = props;
  const modelName = formSpecs.model.name;

  // magic: translate mutation to look like query (e.g. { updateShipment: {} } => { shipment: {} }
  const mutData = Object.values(mutResp.data);
  if (mutData.length === 0) {
    throw new Error("Mutation response empty");
  }
  const mutDataInnards = {} as IGenericHash;
  mutDataInnards[modelName] = mutData[0];

  return mutationToUiFn ? mutationToUiFn(mutDataInnards) : mutDataInnards;
};

const normalizeResponses = (
  props: IGqlFormProps,
  qryResp: GqlHookResp,
  mutResp: GqlHookResp
): IGenericHash => {
  const { queryToUiFn } = props;
  let resp = {} as IGenericHash;

  if (mutResp.data) {
    resp = normalizeMutationResponse(props, mutResp);
  } else if (qryResp.data) {
    resp = queryToUiFn ? queryToUiFn(qryResp.data) : qryResp.data;
  }

  return resp;
};

const shouldQuery = (formSpecs: IGqlFormSpecs): boolean => {
  return formSpecs.gql.pageType !== EGqlFormPageType.Create;
};

const GqlForm = (props: IGqlFormProps): JSX.Element => {
  const { formSpecs, throwForTests: rawThrowForTests } = props;
  const [alert, setAlert] = useState({} as any);
  const [userErrors, setUserErrors] = useState([] as any);

  const throwForTests = !!rawThrowForTests;
  const gqlErrorDisplay = {
    alert,
    setAlert,
    userErrors,
    setUserErrors,
  };

  // Do mutation
  const [mutate, rawMutResponse] = useMutation(
    formSpecs.gql.mutation,
    buildMutationProps(props, gqlErrorDisplay)
  );
  const mutResp = GqlHookResp.fromRawResponse(rawMutResponse);
  const [isRedirecting, setIsRedirecting] = useState(false);

  // Proceed to queries
  const gqlQry = shouldQuery(formSpecs) ? formSpecs.gql.query : NoopQuery;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const rawQryResponse = useQuery(
    gqlQry as any,
    buildQueryProps(props, gqlErrorDisplay)
  );
  const qryResp = GqlHookResp.fromRawResponse(rawQryResponse);

  let errorContent = null;

  if (isRedirecting) {
    return <Redirect to="/expired" />;
  }
  if (qryResp.hasExpiredJwt()) {
    Konsole.info("GqlList: expired JWT, not handled");
    setIsRedirecting(true);
  }

  // Handle failures (queryGql must be done before conditional renders, else IDE will warn)
  if (mutResp.canHandle()) {
    errorContent = mutResp.handle();
  }

  if (qryResp.canHandle() && !errorContent) {
    errorContent = qryResp.handle();
  }

  const resp = normalizeResponses(props, qryResp, mutResp);
  const initialData = (resp && resp[formSpecs.model.name]) || {};

  // Handle success
  return (
    <ErrorBoundary>
      <GqlErrorDisplay {...gqlErrorDisplay} />

      {errorContent}
      {!errorContent &&
        React.createElement(DynamicForm, {
          formSpecs,
          initialData,
          throwForTests,
          saveFn: connectSaveFn(props, mutate),
        })}
    </ErrorBoundary>
  );
};

export default GqlForm;
