import { Typography } from "@material-ui/core";
import React from "react";
import { BrowserJs } from "../../lib/BrowserJs";
import "../App.scss";
import {
  AlwaysThrowError,
  shouldThrowForTests,
} from "../meta/AlwaysThrowError";
import { ErrorBoundary } from "../meta/ErrorBoundary";

const HomePage = (props: any): JSX.Element => {
  const { isAuthenticated } = props.auth0Module() as any;
  BrowserJs.setTitle("Home");

  const text = isAuthenticated
    ? "Welcome to the UI - you're logged in!"
    : "Welcome! Please login before using this app.";

  return (
    <div className="o4-page-without-sidebar">
      <ErrorBoundary>
        {shouldThrowForTests(props.throwForTests) && <AlwaysThrowError />}

        <Typography>{text}</Typography>
      </ErrorBoundary>
    </div>
  );
};

export default HomePage;
