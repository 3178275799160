import { ApolloProvider } from "@apollo/client";
import React from "react";
import { Route, Router, Switch } from "react-router-dom";
import { Konsole } from "../lib/dev/Konsole";
import { ApolloAppClient } from "../lib/ApolloAppClient";
import {
  subscriptionPg,
  newCardPg,
  editInvoicesPg,
  editItemForJobPg,
  editJobPg,
  editOrganizationPg,
  editShipmentForJobPg,
  editUserPg,
  editUserSelfPg,
  itemsForJobPg,
  jobsListPg,
  listCardsPg,
  listUsersPg,
  newItemForJobPg,
  newJobPg,
  newShipmentForJobPg,
  newUserPg,
  shipmentsForJobPg,
  billingHistoryPg,
} from "../lib/UrlHelper";
import "./App.scss";
import AppNavbar from "./AppNavbar";
import { AlwaysThrowError, shouldThrowForTests } from "./meta/AlwaysThrowError";
import { ErrorBoundary } from "./meta/ErrorBoundary";
import PrivateRoute from "./meta/PrivateRoute";
import CardNew from "./pages/CardNew";
import HomePage from "./pages/HomePage";
import InvoicesEdit from "./pages/InvoicesEdit";
import ItemEdit from "./pages/ItemEdit";
import ItemList from "./pages/ItemList";
import ItemNew from "./pages/ItemNew";
import JobEdit from "./pages/JobEdit";
import JobList from "./pages/JobList";
import JobNew from "./pages/JobNew";
import OrganizationEdit from "./pages/OrganizationEdit";
import ShipmentEdit from "./pages/ShipmentEdit";
import ShipmentList from "./pages/ShipmentList";
import ShipmentNew from "./pages/ShipmentNew";
import Expired from "./pages/special/Expired";
import Forbidden from "./pages/special/Forbidden";
import NotFound from "./pages/special/NotFound";
import UserEdit from "./pages/UserEdit";
import UserEditSelf from "./pages/UserEditSelf";
import UserList from "./pages/UserList";
import UserNew from "./pages/UserNew";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import SubscriptionShow from "./pages/SubscriptionShow";
import CardList from "./pages/CardList";
import BillingHistory from "./pages/BillingHistory";

const loggedOutClass = "o4-loggedOut";
const loggedInClass = "o4-loggedIn";

const App = (props: any): JSX.Element => {
  Konsole.debug("== App:start");
  const { auth0Module, appHistory, throwForTests } = props;

  const { loading, isAuthenticated, authBundle } = auth0Module() as any;
  const client = ApolloAppClient.create(isAuthenticated, authBundle);

  // Inject to hint at auth state in snapshot tests
  const authStateClass = isAuthenticated ? loggedInClass : loggedOutClass;

  if (loading) {
    return <div id="pre-auth0-loading-msg">Loading...</div>;
  }

  return (
    <ApolloProvider client={client}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Router history={appHistory}>
          <AppNavbar auth0Module={auth0Module} />

          <ErrorBoundary withLeftMargin>
            <div className={`o4-root-body ${authStateClass}`} role="main">
              {shouldThrowForTests(throwForTests) && <AlwaysThrowError />}

              <Switch>
                {/* UI paths */}
                <Route
                  path="/"
                  exact={true}
                  render={(routeProps) => (
                    <HomePage auth0Module={auth0Module} {...routeProps} />
                  )}
                />

                {/* Item paths */}
                <PrivateRoute
                  path={newItemForJobPg(":id")}
                  exact={true}
                  component={ItemNew}
                  auth0Module={auth0Module}
                />
                <PrivateRoute
                  path={editItemForJobPg(":id", ":itemId")}
                  component={ItemEdit}
                  auth0Module={auth0Module}
                />
                <PrivateRoute
                  path={itemsForJobPg(":id")}
                  component={ItemList}
                  auth0Module={auth0Module}
                />

                {/* Shipment paths */}
                <PrivateRoute
                  path={shipmentsForJobPg(":id")}
                  exact={true}
                  component={ShipmentList}
                  auth0Module={auth0Module}
                />
                <PrivateRoute
                  path={newShipmentForJobPg(":id")}
                  exact={true}
                  component={ShipmentNew}
                  auth0Module={auth0Module}
                />
                <PrivateRoute
                  path={editShipmentForJobPg(":id", ":shipmentId")}
                  component={ShipmentEdit}
                  auth0Module={auth0Module}
                />

                {/* Job paths */}
                <PrivateRoute
                  path={jobsListPg()}
                  exact={true}
                  component={JobList}
                  auth0Module={auth0Module}
                />
                <PrivateRoute
                  path={newJobPg()}
                  exact={true}
                  component={JobNew}
                  auth0Module={auth0Module}
                />
                <PrivateRoute
                  path={editJobPg(":id")}
                  component={JobEdit}
                  auth0Module={auth0Module}
                />

                {/* Billing paths */}
                <PrivateRoute
                  path={subscriptionPg()}
                  component={SubscriptionShow}
                  auth0Module={auth0Module}
                />
                <PrivateRoute
                  path={billingHistoryPg()}
                  component={BillingHistory}
                  auth0Module={auth0Module}
                />
                <PrivateRoute
                  path={editInvoicesPg()}
                  component={InvoicesEdit}
                  auth0Module={auth0Module}
                />

                {/* Card paths */}
                <PrivateRoute
                  path={newCardPg()}
                  component={CardNew}
                  auth0Module={auth0Module}
                />
                <PrivateRoute
                  path={listCardsPg()}
                  component={CardList}
                  auth0Module={auth0Module}
                />

                {/* User paths */}
                <PrivateRoute
                  path={listUsersPg()}
                  component={UserList}
                  auth0Module={auth0Module}
                />
                <PrivateRoute
                  path={newUserPg()}
                  component={UserNew}
                  auth0Module={auth0Module}
                />
                <PrivateRoute
                  path={editUserPg(":id")}
                  component={UserEdit}
                  auth0Module={auth0Module}
                />

                {/* Account paths */}
                <PrivateRoute
                  path={editOrganizationPg()}
                  component={OrganizationEdit}
                  auth0Module={auth0Module}
                />
                <PrivateRoute
                  path={editUserSelfPg()}
                  component={UserEditSelf}
                  auth0Module={auth0Module}
                />

                {/* General app paths */}
                <Route path="/forbidden" component={Forbidden} />
                <Route path="/expired" component={Expired} />

                {/* This is a fallthrough, in case the webserver doesn't handle the HTTP 404 properly */}
                <Route component={NotFound} />
              </Switch>
            </div>
          </ErrorBoundary>
        </Router>
      </MuiPickersUtilsProvider>
    </ApolloProvider>
  );
};

export { loggedInClass, loggedOutClass };

export default App;
