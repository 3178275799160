import { IGenericHash } from "../../types/AppWeb";

const uiToMutationFn = (rawFormData: IGenericHash): IGenericHash => {
  const formData = { ...rawFormData };

  if (formData.quantity && typeof formData.quantity === "string") {
    formData.quantity = parseInt(formData.quantity, 10);
  }

  formData.workAttributes = (formData.workAttributes || []).map(
    (attr: any, index: number) => {
      const newAttr = { ...attr };

      // UI injects this and causes problems in phoenix
      delete newAttr.__typename;

      newAttr.position = index;

      return newAttr;
    }
  );
  return formData;
};

export const sortWorkAttributes = (left: any, right: any): any => {
  return left.position - right.position;
};

export default {
  uiToMutationFn,
};
