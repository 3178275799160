import { IWithNavProps } from "../../types/AppWeb";
import React from "react";
import { AllJobsQuery, CreateJobQuery } from "../../gql/JobQueries";
import { BrowserJs } from "../../lib/BrowserJs";
import { jobsListPg, navFnFor } from "../../lib/UrlHelper";
import { EGqlFormFieldType, EGqlFormPageType } from "../../types/AppEnums";
import Crumbs from "../components/Crumbs";
import GqlForm from "../meta/GqlForm";

const formSpecs = {
  fields: [
    {
      key: "status",
      label: "Status",
      required: true,
      type: EGqlFormFieldType.Text,
    },
    {
      key: "shipBy",
      label: "Ship By",
      required: true,
      type: EGqlFormFieldType.Datetime,
    },
    {
      dataKey: "billingAddress",
      key: "billingAddressId",
      label: "Billing Address",
      required: true,
      type: EGqlFormFieldType.Address,
    },
    {
      dataKey: "shippingAddress",
      key: "shippingAddressId",
      label: "Shipping Address",
      required: true,
      type: EGqlFormFieldType.Address,
    },
  ],
  gql: {
    mutation: CreateJobQuery,
    pageType: EGqlFormPageType.Create,
  },
  model: {
    name: "job",
  },
};

const refetchQueries = [{ query: AllJobsQuery }];

const JobNewTitle = "New Job";

const JobNew = (props: IWithNavProps): JSX.Element => {
  BrowserJs.setTitle(JobNewTitle);

  return (
    <div className={"o4-page-without-sidebar job-page"}>
      <Crumbs location={props.location} />

      <h1>New Job</h1>

      <GqlForm
        formSpecs={formSpecs}
        onMutationCompleted={navFnFor(jobsListPg())}
        refetchQueries={refetchQueries}
      />
    </div>
  );
};

export { JobNewTitle };

export default JobNew;
