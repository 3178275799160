import { Link, Breadcrumbs } from "@material-ui/core";
import React from "react";
import {
  editJobPg,
  itemsForJobPg,
  jobsListPg,
  newJobPg,
  shipmentsForJobPg,
} from "../../lib/UrlHelper";
import Nothing from "./Nothing";

// Regex matches go from first to last
const MatchSpecs = {
  "Create Item": {
    description: "Create Item",
    fn: (path: string, match: RegExpMatchArray): any[] => {
      return [
        { href: jobsListPg(), text: "Jobs" },
        { href: itemsForJobPg(match[1]), text: "Job Items" },
        { href: path, text: "New Item" },
      ];
    },
    regex: new RegExp(/\/jobs\/([0-9a-fA-F-]+)\/items\/new/),
  },
  "Edit Item": {
    description: "Edit Item",
    fn: (path: string, match: RegExpMatchArray): any[] => {
      return [
        { href: jobsListPg(), text: "Jobs" },
        { href: itemsForJobPg(match[1]), text: "Job Items" },
        { href: path, text: "Edit Item" },
      ];
    },
    regex: new RegExp(/\/jobs\/([0-9a-fA-F-]+)\/items\/([0-9a-fA-F-]+)/),
  },
  "List Items": {
    description: "List Items",
    fn: (path: string, match: RegExpMatchArray): any[] => {
      return [
        { href: jobsListPg(), text: "Jobs" },
        { href: itemsForJobPg(match[1]), text: "Job Items" },
      ];
    },
    regex: new RegExp(/\/jobs\/([0-9a-fA-F-]+)\/items/),
  },
  "Create Shipment": {
    description: "Create Shipment",
    fn: (path: string, match: RegExpMatchArray): any[] => {
      return [
        { href: jobsListPg(), text: "Jobs" },
        { href: shipmentsForJobPg(match[1]), text: "Job Shipments" },
        { href: path, text: "Create Shipment" },
      ];
    },
    regex: new RegExp(/\/jobs\/([0-9a-fA-F-]+)\/shipments\/new/),
  },
  ShipmentEdit: {
    description: "Edit Shipment",
    fn: (path: string, match: RegExpMatchArray): any[] => {
      return [
        { href: jobsListPg(), text: "Jobs" },
        { href: shipmentsForJobPg(match[1]), text: "Job Shipments" },
        { href: path, text: "Edit Shipment" },
      ];
    },
    regex: new RegExp(/\/jobs\/([0-9a-fA-F-]+)\/shipments\/([0-9a-fA-F-]+)/),
    samplePath: "/jobs/12-ab/shipments/34-cd",
  },
  "List Shipments": {
    description: "List Shipments",
    fn: (path: string, match: RegExpMatchArray): any[] => {
      return [
        { href: jobsListPg(), text: "Jobs" },
        { href: shipmentsForJobPg(match[1]), text: "Job Shipments" },
      ];
    },
    regex: new RegExp(/\/jobs\/([0-9a-fA-F-]+)\/shipments/),
  },
  "Create Job": {
    description: "Create Job",
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fn: (path: string, match: RegExpMatchArray): any[] => {
      return [
        { href: jobsListPg(), text: "Jobs" },
        { href: newJobPg(), text: "New Job" },
      ];
    },
    regex: new RegExp(/\/jobs\/new/),
  },
  "Edit Job": {
    description: "Edit Job",
    fn: (path: string, match: RegExpMatchArray): any[] => {
      return [
        { href: jobsListPg(), text: "Jobs" },
        { href: editJobPg(match[1]), text: "Edit Job" },
      ];
    },
    regex: new RegExp(/\/jobs\/([0-9a-fA-F-]+)/),
  },
  "Jobs list": {
    description: "Jobs list",
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fn: (path: string, match: RegExpMatchArray): any[] => {
      return [{ href: jobsListPg(), text: "Jobs" }];
    },
    regex: new RegExp(/\/jobs/),
  },
} as any;

const crumbsFor = (path: string): any[] => {
  let breadcrumbs: any[] = [];
  Object.keys(MatchSpecs).forEach((specKey: string): void => {
    const matchSpec = MatchSpecs[specKey];
    const match = path.match(matchSpec.regex);
    // Only set breadcrumbs if it hasn't before
    if (breadcrumbs.length === 0 && match) {
      breadcrumbs = matchSpec.fn(path, match);
    }
  });

  return breadcrumbs;
};

const Crumbs = (props: any): JSX.Element => {
  const crumbs = crumbsFor(props.location.pathname);
  if (crumbs.length === 0) {
    return <Nothing />;
  }

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {crumbs &&
        crumbs.map((crumb: any, idx: number): JSX.Element => {
          return (
            <Link color="inherit" href={crumb.href} key={`blah-${idx}`}>
              {crumb.text}
            </Link>
          );
        })}
    </Breadcrumbs>
  );
};

export default Crumbs;

export { MatchSpecs };
