import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { IAddress } from "../../../../types/AppWeb";
import React from "react";
import {
  AddressesQuery,
  DeleteAddressQuery,
} from "../../../../gql/AddressQueries";
import { GqlHookResp } from "../../../../lib/GqlHookResp";

interface IAddressesProps {
  visible: boolean;
  goCreate: any;
  onSelection: any;
  gotoAddress: any;
}

const onAddressSelect = (address: any, gotoAddress: any) => (): void => {
  gotoAddress(address);
};

const Addresses = (props: IAddressesProps): JSX.Element => {
  const { visible, goCreate, onSelection, gotoAddress } = props;

  const rawQryResponse = useQuery(AddressesQuery, {});
  const qryResp = GqlHookResp.fromRawResponse(rawQryResponse);

  const [mutate, rawMutResponse] = useMutation(DeleteAddressQuery, {
    refetchQueries: [{ query: AddressesQuery }],
    onError: GqlHookResp.defaultErrorHandler,
  });
  const mutResp = GqlHookResp.fromRawResponse(rawMutResponse);

  if (!visible) {
    return <span />;
  }

  if (mutResp.canHandle()) {
    return mutResp.handle();
  }

  if (qryResp.canHandle()) {
    return qryResp.handle();
  }

  const { data } = rawQryResponse;
  const { addresses } = data;

  return (
    <>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table className="o4-table">
            <TableHead>
              <TableRow>
                <TableCell>First</TableCell>
                <TableCell>Last</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {addresses.map((address: IAddress, index: number) => {
                const onAddressClick = (): void => {
                  onSelection(address);
                };
                const deleteFn = (): any => {
                  mutate({ variables: { id: address.id } });
                };

                return (
                  <TableRow key={`address-${index}`}>
                    <TableCell onClick={onAddressClick}>
                      {address.firstName}
                    </TableCell>
                    <TableCell onClick={onAddressClick}>
                      {address.lastName}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={onAddressSelect(address, gotoAddress)}
                      >
                        Edit
                      </Button>

                      <Button
                        variant="contained"
                        color="primary"
                        onClick={deleteFn}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={goCreate}>
          New Address
        </Button>
      </DialogActions>
    </>
  );
};

export default Addresses;
