import {
  Error401Text,
  Error500Text,
  GqlHookResp,
  ManagedApplicationErrors,
} from "../../lib/GqlHookResp";
import { ApolloError } from "@apollo/client";
import _includes from "lodash/includes";
import { Konsole } from "../../lib/dev/Konsole";
import { IGqlMutationProps } from "../../types/GqlForm";
import { IRefetchQuery } from "../../types/AppWeb";
import { buildShowAlertHandler } from "./gqlForm/SnackbarAlertHelpers";
import { IGqlErrorDisplay } from "./GqlErrorDisplay";

export const GqlErrorText = "There was a problem with the update";

export interface IGqlHandlerOpts {
  refetchQueries?: IRefetchQuery[];
  onMutationCompleted?: any;
  onMutationError?: any;
  onQueryError?: any;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const defaultMutationSuccessFn = (data: any): void => {
  Konsole.info("Successfully saved");
};

// TODO make GqlForm and GqlList interfaces similarly
export const buildMutationProps = (
  handlerOpts: IGqlHandlerOpts,
  errorDisplayOpts: IGqlErrorDisplay
): IGqlMutationProps => {
  const { refetchQueries, onMutationCompleted } = handlerOpts;
  const { setAlert } = errorDisplayOpts;
  const mutationProps = {} as IGqlMutationProps;

  const showAlert = buildShowAlertHandler(setAlert);

  // We'll want to add some alerting around the user-supplied callbacks
  const normalizedOnCompleted = onMutationCompleted || defaultMutationSuccessFn;

  if (refetchQueries) {
    mutationProps.refetchQueries = refetchQueries;
  }

  mutationProps.onCompleted = (data: any) => {
    normalizedOnCompleted(data);

    Konsole.info("GqlForm/List:onMutationCompleted:data", data);
    showAlert("Successfully updated", "success");
  };
  mutationProps.onError = buildGqlUiErrorHandler(handlerOpts, errorDisplayOpts);
  return mutationProps;
};

export const buildGqlUiErrorHandler = (
  handlerOpts: IGqlHandlerOpts,
  errorDisplayOpts: IGqlErrorDisplay
): any => {
  const { onMutationError } = handlerOpts;
  const { setUserErrors, setAlert } = errorDisplayOpts;

  const showAlert = buildShowAlertHandler(setAlert);

  const normalizedOnErrorProp =
    onMutationError || GqlHookResp.defaultErrorHandler;

  return (error: ApolloError) => {
    const { graphQLErrors, networkError } = error;
    normalizedOnErrorProp(error);

    const userErrors = graphQLErrors
      .map((gqlError: any) => {
        return gqlError.message;
      })
      .filter((errorMessage: string) => {
        return !_includes(ManagedApplicationErrors, errorMessage);
      });
    setUserErrors(userErrors);

    Konsole.info("GqlForm/List:onMutationError:userErrors", userErrors);
    Konsole.info("GqlForm/List:onMutationError:error", error);

    // Specifically managed alerts
    if (GqlHookResp.hasInternalServerError(graphQLErrors)) {
      showAlert(Error500Text, "error");
    } else if (GqlHookResp.hasAuthErrorFromApolloError(networkError)) {
      showAlert(Error401Text, "error");

      // General alerts
    } else {
      showAlert(GqlErrorText, "error");
    }
  };
};
