import { Dialog, DialogTitle } from "@material-ui/core";
import { IAddress } from "../../../../types/AppWeb";
import React, { Component, ReactNode } from "react";
import DefaultDialogProps from "../../DefaultDialogProps";
import Address from "./Address";
import Addresses from "./Addresses";
import NewAddress from "./NewAddress";

interface IAddressDialogProps {
  isOpen: boolean;
  onClose(): void;
  onSelection(addr: IAddress): void;
}

interface IAddressDialogState {
  mode: string; // todo can tighten
  selected: IAddress | null;
}

export class AddressDialog extends Component<
  IAddressDialogProps,
  IAddressDialogState
> {
  constructor(args: any) {
    super(args);
    this.state = { mode: "list", selected: null };
  }

  public render = (): ReactNode => {
    const { isOpen } = this.props;
    const { mode, selected } = this.state;

    return (
      <div className={"o4-job-form o4-aligned-form"}>
        <Dialog
          className="o4-job-form o4-aligned-form"
          open={isOpen}
          onClose={this.onClose}
          {...DefaultDialogProps}
        >
          <DialogTitle id="customized-dialog-title">Addresses</DialogTitle>
          <Addresses
            visible={mode === "list"}
            goCreate={this.changeMode("create")}
            gotoAddress={this.gotoAddress}
            onSelection={this.onSelection}
          />
          <NewAddress
            visible={mode === "create"}
            goCancel={this.changeMode("list")}
            onSuccess={this.onCreationSuccess}
          />
          <Address
            visible={mode === "show" && selected !== null}
            goCancel={this.changeMode("list")}
            onSuccess={this.onUpdateSuccess}
            address={selected}
          />
        </Dialog>
      </div>
    );
  };

  private onSelection = (address: IAddress): void => {
    const { onSelection } = this.props;
    onSelection(address);
  };

  private readonly onCreationSuccess = (): void => {
    this.changeMode("list")();
  };

  private onUpdateSuccess = (): void => {
    this.changeMode("list")();
  };

  private changeMode = (newMode: string) => (): void => {
    this.setState({ ...this.state, mode: newMode });
  };

  private readonly onClose = (): void => {
    const { onClose } = this.props;
    onClose();
  };

  private gotoAddress = (address: any): void => {
    this.setState({
      ...this.state,
      mode: "show",
      selected: address,
    });
  };
}

export default AddressDialog;
