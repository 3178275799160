import { ApolloError } from "@apollo/client";

const handledApolloErrors: any = {
  stripe_customer_missing: "We were not able to look up your Customer ID.",
};

const handledStripeErrors: any = {
  setup_intent_unexpected_state:
    "There was an issue requesting the Card Setup initiation details.",
};

// TODO validate defaultMsg and handledErrors to be properly formatted (capitalized, period end)
const buildApolloUserErrorMsg = (
  apolloError: ApolloError,
  defaultMsg: string
) => {
  const handledError = handledApolloErrors[apolloError.message];
  if (handledError) {
    return `${defaultMsg} ${handledError}`;
  }
  return defaultMsg;
};

const buildStripeUserErrorMsg = (stripeError: any, defaultMsg: string) => {
  const handledError = handledStripeErrors[stripeError.code];
  if (handledError) {
    return `${defaultMsg} ${handledError}`;
  }
  return defaultMsg;
};

export { buildApolloUserErrorMsg, buildStripeUserErrorMsg };
