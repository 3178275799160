import _isNil from "lodash/isNil";
import React, { ReactText } from "react";
import Nothing from "../Nothing";
import { DatePicker } from "@material-ui/pickers";
import Moment from "moment";

interface IFormDateProps {
  disabled?: boolean;
  id: string;
  label: string;
  required?: boolean;
  value: string | ReactText | null;
  visible?: boolean;
  onChange?(data: any): void;
}

const DateSelector = (props: IFormDateProps): JSX.Element => {
  const { onChange, label, value, id, required, disabled, visible } = props;

  if (!_isNil(visible) && !visible) {
    return <Nothing />;
  }

  const formattedDate = value === null ? null : Moment(value);

  return (
    <div className="o4-input o4-input-date-selector">
      <DatePicker
        id={id}
        label={label}
        required={required}
        value={formattedDate}
        className=""
        InputLabelProps={{ shrink: true }}
        onChange={onChange as any}
        format="M/D/YYYY"
        disabled={disabled}
      />
    </div>
  );
};

export default DateSelector;

export const DateInputPlaceholder = "MM/DD/YYYY";
