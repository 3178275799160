import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import Sidebar, { AccountSidebarLinks } from "../components/Sidebar";
import CardSetupForm from "./billingEdit/CardSetupForm";
import { AppConf } from "../../conf/AppConf";
import { ErrorBoundary } from "../meta/ErrorBoundary";

const stripePromise = loadStripe(AppConf.conf.stripePublishableKey);

const CardNewTitle = "New Credit Card";

const CardNew = (): JSX.Element => {
  return (
    <div className="o4-layout-page account-page">
      <Sidebar links={AccountSidebarLinks} activeKey="acct-sidebar-new-card" />

      <div className="o4-layout-main">
        <h1>{CardNewTitle}</h1>

        <ErrorBoundary>
          <Elements stripe={stripePromise}>
            <CardSetupForm />
          </Elements>
        </ErrorBoundary>
      </div>
    </div>
  );
};

export { CardNewTitle };

export default CardNew;
