import { gql } from "@apollo/client";

export const JobWithShipmentsQuery = gql`
  query JobWithShipmentsQuery($id: String!) {
    job(id: $id) {
      id
      shipBy
      status
      updatedAt
      insertedAt
      shipments {
        id
        method
        jobId
        shippingAddress {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const CreateShipmentQuery = gql`
  mutation CreateShipmentQuery(
    $jobId: String!
    $method: String
    $shippingAddressId: String
  ) {
    createShipment(
      jobId: $jobId
      method: $method
      shippingAddressId: $shippingAddressId
    ) {
      id
      method
      jobId
      shippingAddress {
        id
        firstName
        lastName
      }
    }
  }
`;

export const UpdateShipmentQuery = gql`
  mutation updateShipment(
    $id: String!
    $method: String
    $shippingAddressId: String
  ) {
    updateShipment(
      id: $id
      method: $method
      shippingAddressId: $shippingAddressId
    ) {
      id
      method
      jobId
      shippingAddress {
        id
        firstName
        lastName
      }
    }
  }
`;

export const ShipmentQuery = gql`
  query ShipmentQuery($id: String!) {
    shipment(id: $id) {
      id
      method
      jobId
      shippingAddress {
        id
        firstName
        lastName
      }
    }
  }
`;

export const DeleteShipmentQuery = gql`
  mutation DeleteShipmentQuery($id: String!) {
    deleteShipment(id: $id) {
      id
    }
  }
`;
