import { useMutation, useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { ChangeEvent, useState } from "react";
import { UpdateOrganizationQuery } from "../../../gql/OrganizationQueries";
import { Konsole } from "../../../lib/dev/Konsole";
import TextInput from "../../components/fieldTypes/TextInput";
import Nothing from "../../components/Nothing";

import CardSection from "./CardSection";
import {
  buildHideAlertHandler,
  buildShowAlertHandler,
} from "../../meta/gqlForm/SnackbarAlertHelpers";
import { Button, Snackbar } from "@material-ui/core";
import { SnackbarAutoHideDuration } from "../../../conf/AppConf";
import { Alert } from "@material-ui/lab";
import {
  buildApolloUserErrorMsg,
  buildStripeUserErrorMsg,
} from "../../../lib/formatters/ErrorMessageFormatter";
import { ListCardsQuery } from "../CardList";
import { BillingHistoryQuery } from "../BillingHistory";

const newSetupIntentGql = gql`
  {
    newSetupIntent {
      setupIntentId
      clientSecret
    }
  }
`;

const buildCardSetupData = (opts: any) => {
  const { firstName, lastName, elements } = opts;
  return {
    payment_method: {
      billing_details: {
        name: `${firstName} ${lastName}`,
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      card: elements.getElement(CardElement),
    },
  };
};

const extractpaymentMethodStrid = (confirmCardSetupResult: any): any => {
  const { setupIntent } = confirmCardSetupResult;
  const { payment_method: paymentMethod }: any = setupIntent;
  return paymentMethod;
};

const CardSetupForm = (): any => {
  const stripe = useStripe();
  const elements = useElements();
  const [alert, setAlert] = useState({} as any);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const showAlert = buildShowAlertHandler(setAlert);
  const hideAlert = buildHideAlertHandler(alert, setAlert);

  const qryResponse = useQuery(newSetupIntentGql, {});
  // eslint-disable-next-line
  const [mutate, rawMutResponse] = useMutation(UpdateOrganizationQuery, {
    onCompleted: (data: any) => {
      showAlert("We saved your credit card successfully.");
      Konsole.info("mutation,UpdateOrganizationQuery:onCompleted", data);
    },
    onError: (error) => {
      const msg = buildApolloUserErrorMsg(
        error,
        "Something went wrong with saving your credit card."
      );
      showAlert(msg, "error");
      Konsole.info("mutation,UpdateOrganizationQuery:error", error);
    },
    refetchQueries: [
      { query: newSetupIntentGql },
      // Probably expensive - consider using useMutation's update() to cache.evict()
      { query: ListCardsQuery },
      { query: BillingHistoryQuery },
    ],
  });

  let createdClientSecret: any = null;
  if (qryResponse.data) {
    createdClientSecret = qryResponse.data.newSetupIntent.clientSecret;
  }

  if (createdClientSecret !== null) {
    const handleSubmit = async (event: any) => {
      // Don't allow default form submission here, which would refresh the page.
      event.preventDefault();

      // Stripe.js has not yet loaded. Disable form submission until Stripe.js has loaded
      if (!stripe || !elements) {
        return;
      }

      const confirmCardSetupResult = await stripe.confirmCardSetup(
        createdClientSecret,
        buildCardSetupData({ firstName, lastName, elements })
      );

      const stripeError = confirmCardSetupResult.error;
      if (stripeError) {
        // Display result.error.message in your UI.
        Konsole.error("Stripe setup problem:", stripeError);
        const userErrorMsg = buildStripeUserErrorMsg(
          stripeError,
          "We were not able to prepare the Credit Card form."
        );
        showAlert(userErrorMsg, "error");
      } else {
        // Setup succeeded: display success message, send result.setupIntent.payment_method to our server and save the
        // card to a Customer
        mutate({
          variables: {
            paymentMethodStrid: extractpaymentMethodStrid(
              confirmCardSetupResult
            ),
          },
        });
      }
    };

    const changeTextState =
      (setFn: any) =>
      (event: ChangeEvent<HTMLInputElement>): void =>
        setFn(event.target.value);

    return (
      (
        <form onSubmit={handleSubmit}>
          <Snackbar
            open={alert.isOpen}
            autoHideDuration={SnackbarAutoHideDuration}
            onClose={hideAlert}
          >
            <Alert
              severity={alert.severity}
              variant="filled"
              onClose={hideAlert}
            >
              {alert.message}
            </Alert>
          </Snackbar>
          <TextInput
            id="card-first-name"
            label="First Name"
            value={firstName}
            onChange={changeTextState(setFirstName)}
          />
          <TextInput
            id="card-last-name"
            label="Last Name"
            value={lastName}
            onChange={changeTextState(setLastName)}
          />

          <br />
          <CardSection />
          <Button
            disabled={!stripe}
            variant="contained"
            color="primary"
            type="submit"
          >
            Save Card
          </Button>
        </form>
      ) || <Nothing />
    );
  }

  return (
    <p>We&apos;re loading the credit card form, this may take a while..</p>
  );
};

export default CardSetupForm;
