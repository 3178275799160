import { TGqlFieldSpec } from "../../../types/FieldTypes";
import { IGqlFormFieldSpec } from "../../../types/GqlForm";
import { IAddress, IGenericHash } from "../../../types/AppWeb";
import AddressFormatter from "../../../lib/formatters/AddressFormatter";
import DynamicForm from "../DynamicForm";
import { getDefaultFieldValue } from "./FieldTypeHelpers";

export const addressToLabel = (
  datum: IGenericHash,
  fieldSpec: TGqlFieldSpec
): string => {
  return AddressFormatter.fullName(datum[fieldSpec.key]);
};

export const buildAddrChangeFn =
  (spec: IGqlFormFieldSpec, origThis: DynamicForm) =>
  (addr: IGenericHash): void => {
    const { key, dataKey } = spec;
    const newData = { ...origThis.state.formData } as any;
    newData[key] = addr.id;
    if (dataKey) {
      newData[dataKey] = addr;
    } else {
      throw new Error("The field's DataKey is null");
    }
    origThis.setState({ formData: newData });
  };

export const addressToValue = (
  spec: TGqlFieldSpec,
  formData: IGenericHash,
  initialData: IGenericHash
): string => {
  const val = getDefaultFieldValue(
    spec,
    formData,
    initialData
  ) as IAddress | null;
  if (!val) {
    return "";
  } // avoid 'undefined undefined' when passing into fullName
  return AddressFormatter.fullName(val);
};
