import Moment from "moment";

class DateFormatter {
  /**
   * @param isoDatetime [String] eg 2019-04-08T00:40:03.410586Z
   * @return locale date, eg  5/1/2019, 5:40:03 PM
   */
  public static friendlyDateTime = (isoDatetime: Date | string): string => {
    return Moment(isoDatetime).format("lll");
  };

  /**
   * @param localeDate [String] eg 5/1/2019, 5:40:03 PM
   * @return Date object
   */
  public static localeStringToDate = (localeDate: string): Date => {
    return new Date(localeDate);
  };

  public static isoToDate = (isoDateTime: string): Date => {
    return Moment(isoDateTime).toDate();
  };

  public static dateToIsoString = (date: Date): string => {
    return Moment(date).utc().toISOString();
  };

  public static dateToLocaleString = (date: Date): string => {
    return date.toLocaleString();
  };
}

export default DateFormatter;
