import React from "react";

export class WorkAttributes {
  public static generateDescription = (workAttributes: any): any => {
    const descriptionLines = WorkAttributes.summarizeAttributes(workAttributes);
    return (
      <React.Fragment>
        {descriptionLines.map((descriptionLine: any, idx: number) => {
          return (
            <React.Fragment key={`description-line-${idx}`}>
              {descriptionLine}
              <br />
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  };

  public static summarizeAttributes = (workAttributes: any): any => {
    return workAttributes.map((attribute: any) => {
      return `${attribute.name}: ${attribute.value}`;
    });
  };
}
