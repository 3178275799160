import { IWithNavProps } from "../../types/AppWeb";
import React from "react";
import { ItemQuery, UpdateItemQuery } from "../../gql/WorkItemQueries";
import { itemsForJobPg, navFnFor } from "../../lib/UrlHelper";
import { EGqlFormFieldType, EGqlFormPageType } from "../../types/AppEnums";
import Crumbs from "../components/Crumbs";
import GqlForm from "../meta/GqlForm";
import ItemUtil from "./ItemUtil";

const formSpecs = {
  fields: [
    {
      disabled: true,
      key: "id",
      label: "ID",
      type: EGqlFormFieldType.Text,
    },
    {
      key: "quantity",
      label: "Quantity",
      required: true,
      type: EGqlFormFieldType.Text,
    },
    {
      key: "productName",
      label: "Product",
      required: true,
      type: EGqlFormFieldType.Text,
    },
    {
      key: "total",
      label: "Total",
      required: true,
      type: EGqlFormFieldType.Text,
    },
    {
      key: EGqlFormFieldType.WorkAttributes,
      label: "Description",
      required: true,
      type: EGqlFormFieldType.WorkAttributes,
    },
  ],
  gql: {
    mutation: UpdateItemQuery,
    pageType: EGqlFormPageType.Edit,
    query: ItemQuery,
  },
  model: {
    name: "workItem",
  },
};

const ItemEditTitle = "Edit Item";

const ItemEdit = (props: IWithNavProps): JSX.Element => {
  const { id: jobId, itemId } = props.match.params;

  return (
    <div className="o4-page-without-sidebar">
      <Crumbs location={props.location} />

      <h1>{ItemEditTitle}</h1>

      <GqlForm
        formSpecs={formSpecs}
        queryVariables={{ id: itemId }}
        onMutationCompleted={navFnFor(itemsForJobPg(jobId))}
        uiToMutationFn={ItemUtil.uiToMutationFn}
      />
    </div>
  );
};

export { ItemEditTitle };

export default ItemEdit;
