import { IFieldTypes } from "../../../types/FieldTypes";
import { noop1ArgFn } from "../../../lib/Noop";
import AddressSelector from "../../components/fieldTypes/AddressSelector";
import DateSelector from "../../components/fieldTypes/DateSelector";
import PasswordInput from "../../components/fieldTypes/PasswordInput";
import TextInput from "../../components/fieldTypes/TextInput";
import WorkAttributesInput from "../../components/fieldTypes/WorkAttributesInput";
import {
  addressToLabel,
  addressToValue,
  buildAddrChangeFn,
} from "./AddressField";
import { buildDateTimeChangeFn, datetimeToLabel } from "./DatetimeField";
import { getDefaultFieldValue } from "./FieldTypeHelpers";
import { buildTextChangeFn, textDatumToLabel } from "./TextField";
import {
  workAttributesToLabel,
  workAttributesToValue,
} from "./WorkAttributesField";

// These keys correspond to AppEnums
export const FieldTypes: IFieldTypes = {
  address: {
    buildChangeFn: buildAddrChangeFn,
    componentClass: AddressSelector,
    datumToLabelFn: addressToLabel,
    toValueFn: addressToValue,
  },
  datetime: {
    buildChangeFn: buildDateTimeChangeFn,
    componentClass: DateSelector,
    datumToLabelFn: datetimeToLabel,
    toValueFn: getDefaultFieldValue,
  },
  password: {
    buildChangeFn: buildTextChangeFn,
    componentClass: PasswordInput,
    datumToLabelFn: textDatumToLabel,
    toValueFn: getDefaultFieldValue,
  },
  text: {
    buildChangeFn: buildTextChangeFn,
    componentClass: TextInput,
    datumToLabelFn: textDatumToLabel,
    toValueFn: getDefaultFieldValue,
  },
  workAttributes: {
    buildChangeFn: noop1ArgFn,
    componentClass: WorkAttributesInput,
    datumToLabelFn: workAttributesToLabel,
    toValueFn: workAttributesToValue,
  },
};
