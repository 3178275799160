import _clone from "lodash/clone";
import { IGenericHash } from "../../../types/AppWeb";
import React, { Component } from "react";
import { v4 as uuidv4 } from "uuid";
import WorkAttributeRow from "./WorkAttributesInput/WorkAttributeRow";

export interface IWorkAttributesInputProps {
  value: IGenericHash;
  setStateForChild(data: IGenericHash): void;
}

class WorkAttributesInput extends Component<
  IWorkAttributesInputProps,
  unknown
> {
  public render = (): JSX.Element => {
    const { workAttributes } = this.props.value;
    const normalWorkAttributes = workAttributes || [];

    return (
      <>
        <h2>Description</h2>
        <button onClick={this.addAttribute}>Add</button>
        <br />
        <br />
        {normalWorkAttributes.map((attribute: any) => {
          if (attribute._crudCmd === "delete") {
            return null;
          }
          return (
            <WorkAttributeRow
              attribute={attribute}
              key={`attribute-row-${attribute.id}`}
              updateAttributeName={this.updateAttributeName}
              updateAttributeValue={this.updateAttributeValue}
              deleteAttribute={this.deleteAttribute(attribute)}
            />
          );
        })}
      </>
    );
  };

  private addAttribute = (): void => {
    const { value } = this.props;
    if (value === null) {
      return;
    }

    // Clone value
    const newWorkItem = _clone(value);
    let { workAttributes } = newWorkItem;
    workAttributes = workAttributes || [];
    workAttributes.push({
      __typename: "workAttribute",
      _crudCmd: "create",
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      id: uuidv4(),
      name: "",
      value: "",
    });
    newWorkItem.workAttributes = workAttributes;
    this.setSanitizedStateForChild(newWorkItem);
  };

  private deleteAttribute =
    (updatedWorkAttribute: IGenericHash) => (): void => {
      const { value } = this.props;
      if (value === null) {
        return;
      }
      // Clone value
      const newWorkItem = _clone(value);
      const { workAttributes } = newWorkItem;

      workAttributes.forEach((attr: IGenericHash) => {
        if (attr.id === updatedWorkAttribute.id) {
          attr._crudCmd = "delete";
        }
      });

      this.setSanitizedStateForChild(newWorkItem);
    };

  private updateAttributeName = (event: any): void => {
    const keyRegex = /attribute-name-/;
    const attributeAttr = "name";
    this.updateAttribute(event, keyRegex, attributeAttr);
  };

  private updateAttributeValue = (event: any): void => {
    const keyRegex = /attribute-value-/;
    const attributeAttr = "value";
    this.updateAttribute(event, keyRegex, attributeAttr);
  };

  // Note: 'position' will be set in ItemUtil.uiToMutationFn
  private updateAttribute = (
    event: any,
    keyRegex: RegExp,
    attributeAttr: string
  ): void => {
    const newValue = event.currentTarget.value;
    const attributeKey = event.currentTarget.id;
    const attributeId = attributeKey.replace(keyRegex, "");
    const { value } = this.props;

    if (value === null) {
      return;
    }

    // Clone value
    const newWorkItem = _clone(value);
    const { workAttributes } = newWorkItem;

    // Update clone
    workAttributes.forEach((workAttribute: IGenericHash) => {
      if (workAttribute.id === attributeId) {
        workAttribute[attributeAttr] = newValue;
        if (workAttribute._crudCmd !== "create") {
          workAttribute._crudCmd = "update";
        }
      }
    });
    this.setSanitizedStateForChild(newWorkItem);
  };

  private setSanitizedStateForChild = (newWorkItem: IGenericHash) => {
    const { setStateForChild } = this.props;
    // Delete other attributes -- we have no business setting them
    const sanitizedWorkItem = { workAttributes: newWorkItem.workAttributes };
    setStateForChild(sanitizedWorkItem);
  };
}

export default WorkAttributesInput;
