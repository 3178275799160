import { gql } from "@apollo/client";

export const JobWithItemsQuery = gql`
  query JobWithItemsQuery($id: String!) {
    job(id: $id) {
      id
      shipBy
      status
      updatedAt
      insertedAt
      billingAddress {
        id
        firstName
        lastName
      }
      workItems {
        id
        productName
        quantity
        jobId
        total
        workAttributes {
          id
          name
          value
          position
        }
      }
    }
  }
`;

export const DeleteWorkItemQuery = gql`
  mutation DeleteWorkItemQuery($id: String!) {
    deleteWorkItem(id: $id) {
      id
      quantity
      productName
      total
    }
  }
`;

export const ItemQuery = gql`
  query ItemQuery($id: String!) {
    workItem(id: $id) {
      id
      productName
      quantity
      jobId
      total
      workAttributes {
        id
        name
        value
        position
      }
    }
  }
`;

export const UpdateItemQuery = gql`
  mutation UpdateItemQuery(
    $id: String!
    $productName: String
    $quantity: Int
    $workAttributes: [WorkAttributeInput]
    $total: String
  ) {
    updateWorkItem(
      id: $id
      productName: $productName
      quantity: $quantity
      workAttributes: $workAttributes
      total: $total
    ) {
      id
      quantity
      productName
      total
      workAttributes {
        id
        name
        value
        position
      }
    }
  }
`;

export const CreateItemQuery = gql`
  mutation CreateItemQuery(
    $jobId: String!
    $productName: String!
    $quantity: Int!
    $workAttributes: [WorkAttributeInput]
    $total: String
  ) {
    createWorkItem(
      jobId: $jobId
      productName: $productName
      quantity: $quantity
      workAttributes: $workAttributes
      total: $total
    ) {
      id
      quantity
      productName
      total
      workAttributes {
        id
        name
        value
        position
      }
    }
  }
`;
