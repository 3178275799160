import { ApolloClient, ApolloError } from "@apollo/client";
import _isNil from "lodash/isNil";
import _isString from "lodash/isString";
import _includes from "lodash/includes";
import _some from "lodash/some";
import React from "react";
import { Konsole } from "./dev/Konsole";

const Error500Text = "There was an unexpected error on the server.";
const Error401Text = "There was an error while authenticating your request.";
const EmptyHashText = "The server sent back an empty response.";
const ErrorNetworkText = "There was an unexpected error from the server.";

const EmptyHashNetworkErrorRegex = /Server response was missing for query/;

const InternalServerErrorCode = "internal_server_error";
const ManagedApplicationErrors = [InternalServerErrorCode];

const DefaultErrorHandlerPrefix = "GqlHookResp.defaultErrorHandler";

export class GqlHookResp {
  public static fromRawResponse = (rawResponse: any): GqlHookResp => {
    const { error, loading, data, client } = rawResponse;
    return new GqlHookResp(error, loading, data, client);
  };

  public readonly data: any;
  private readonly error: any;
  private readonly loading: any;
  private readonly client: any;

  constructor(
    error: ApolloError | undefined,
    loading: boolean,
    data: any,
    client: ApolloClient<any>
  ) {
    this.error = error;
    this.loading = loading;
    this.data = data;
    this.client = client;
  }

  public static defaultErrorHandler = (error: ApolloError): void => {
    Konsole.error(DefaultErrorHandlerPrefix, error);
  };

  public canHandle = (): boolean => {
    return (
      // this.hasApolloError() ||
      // this.hasManagedApplicationError() ||
      this.isLoading()
    );
  };

  public handle = (): JSX.Element => {
    if (this.isLoading()) {
      return <div>Loading..</div>;
    }

    // if (this.hasApolloError()) {
    //   return this.handleApolloState();
    // }
    //
    // if (this.hasManagedApplicationError()) {
    //   return this.handleApplicationError();
    // }

    return <div>{Error500Text}</div>;
  };

  private static hasExpiredJwtFromNetworkError = (
    networkError: any
  ): boolean => {
    return (
      networkError &&
      networkError.result &&
      networkError.result.errors &&
      Array.isArray(networkError.result.errors) &&
      networkError.result.errors[0] &&
      networkError.result.errors[0].message &&
      networkError.result.errors[0].message === "expired_jwt"
    );
  };

  public hasExpiredJwt = (): boolean => {
    if (!this.error) return false;
    return GqlHookResp.hasExpiredJwtFromNetworkError(this.error.networkError);
  };

  private hasApolloError = (): boolean => {
    return !_isNil(this.error) && !this.hasApplicationError();
  };

  private isLoading = (): boolean => {
    return !!this.loading;
  };

  private hasApplicationError = (): boolean => {
    if (_isNil(this.error)) {
      return false;
    }
    const { graphQLErrors } = this.error;
    return _isNil(graphQLErrors) || graphQLErrors.length > 0;
  };

  private getError = (): any => {
    if (
      this.error &&
      this.error.networkError &&
      this.error.networkError.result &&
      this.error.networkError.result.error &&
      _isString(this.error.networkError.result.error)
    ) {
      return this.error.networkError.result.error;
    }

    return null;
  };

  private hasHttpCode = (code: number): boolean => {
    return (
      this.error &&
      this.hasHttpCodeFromNetworkError(this.error.networkError, code)
    );
  };

  private hasHttpCodeFromNetworkError = (
    networkError: any,
    code: number
  ): boolean => {
    return networkError && networkError.statusCode === code;
  };

  private hasAuthError = (): boolean => {
    return this.hasExpiredJwt() || this.hasHttpCode(401);
  };

  public static hasAuthErrorFromApolloError = (error: any): boolean => {
    return (
      !!error && GqlHookResp.hasExpiredJwtFromNetworkError(error.networkError)
    );
  };

  public static hasManagedError = (data: any): boolean => {
    return (
      !!data &&
      !!data.errors &&
      _some(data.errors, (msg: any) => {
        console.log("msg", msg);
        return false;
      })
    );
  };

  public static hasInternalServerError = (graphQLErrors: any): boolean => {
    return _some(graphQLErrors, (error: any) => {
      return (
        error === InternalServerErrorCode ||
        error.message === InternalServerErrorCode
      );
    });
  };

  /** Renders exceptional states for: loading and network errors (404/500s)
   */
  private handleApolloState = (): JSX.Element => {
    if (this.error) {
      const { networkError } = this.error;
      Konsole.warn(
        "GqlHookResp.handleApolloState:networkError.result",
        networkError.result
      );

      if (_isNil(networkError)) {
        return (
          <div>The server responded with an error, but no explanation!</div>
        );
      } else if (networkError.statusCode === 500) {
        return <div>{Error500Text}</div>;
      } else if (networkError.statusCode === 404) {
        return (
          <div>
            The database wasn&apos;t able to find the data you requested!
          </div>
        );
      } else if (networkError.statusCode === 401) {
        return <div>{Error401Text}</div>;
      } else if (networkError.statusCode === 400 && this.getError()) {
        return (
          <div>We encountered a problem with the request from the server!</div>
        );
      } else if (networkError.message === "Failed to fetch") {
        return <div>The server is currently unavailable!</div>;
      } else if (networkError.message.match(EmptyHashNetworkErrorRegex)) {
        return <div>{EmptyHashText}</div>;
      }

      return <div>{ErrorNetworkText}</div>;
    } else {
      throw new Error("There was an unexpected error.");
    }
  };

  private handleApplicationError = (): JSX.Element => {
    if (this.error) {
      const { message } = this.error;
      Konsole.warn("GqlHookResp.handleApplicationError:error.message", message);

      if (message === "internal_server_error") {
        Konsole.warn("Rendering internal_server_error div");
        return <div>There was an unexpected error from the application!</div>;
      } else {
        throw new Error(
          "GqlHookResp:handleApplicationError: tried to handle a message we don't support"
        );
      }
    } else {
      throw new Error(
        "GqlHookResp:handleApplicationError: tried to handle an error that doesn't exist"
      );
    }
  };

  private hasManagedApplicationError = (): boolean => {
    if (_isNil(this.error)) {
      return false;
    }

    return _includes(ManagedApplicationErrors, this.error.message);
  };
}

export {
  DefaultErrorHandlerPrefix,
  Error500Text,
  Error401Text,
  ErrorNetworkText,
  EmptyHashText,
  EmptyHashNetworkErrorRegex,
  ManagedApplicationErrors,
};
