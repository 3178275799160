import { Button, DialogActions, DialogContent } from "@material-ui/core";
import _isClone from "lodash/clone";
import _isNil from "lodash/isNil";
import { IAddress } from "../../../../types/AppWeb";
import React, { ChangeEvent, Component } from "react";
import TextInput from "../TextInput";

interface IAddressPresProps {
  address: IAddress | null;
  goCancel(): void;
  onSave(addr: IAddress): void;
}

interface IAddressPresState {
  address: IAddress | null;
}

export class AddressPres extends Component<
  IAddressPresProps,
  IAddressPresState
> {
  constructor(args: any) {
    super(args);
    this.state = { address: null };
  }

  public componentDidMount(): void {
    const { address } = this.props;
    this.setState({ address });
  }

  public render = (): JSX.Element => {
    const { goCancel } = this.props;
    const { address } = this.state;

    if (address === null) {
      return <span />;
    }
    const { firstName, lastName } = address;

    return (
      <>
        <DialogContent>
          <TextInput
            onChange={this.setFirstName}
            label={"First name"}
            id={"address-first-name-input"}
            required={true}
            value={firstName}
          />

          <TextInput
            onChange={this.setLastName}
            label={"Last name"}
            id={"address-last-name-input"}
            required={true}
            value={lastName}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={goCancel}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={this.save}>
            Save
          </Button>
        </DialogActions>
      </>
    );
  };

  private readonly setFirstName = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    this.updateAddressParam(event.target.value, "firstName");
  };

  private readonly setLastName = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    this.updateAddressParam(event.target.value, "lastName");
  };

  private updateAddressParam = (val: any, key: string): void => {
    const { address } = this.state;
    const newAddress = _isClone(address);
    if (_isNil(newAddress)) {
      throw new Error("Address cannot be nil");
    }
    newAddress[key] = val;
    this.setState({ address: newAddress });
  };

  private save = (): void => {
    const { address } = this.state;
    const { onSave } = this.props;
    if (address) {
      onSave(address);
    }
  };
}

export default AddressPres;
