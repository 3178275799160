import { useMutation } from "@apollo/client";
import { IAddress } from "../../../../types/AppWeb";
import React from "react";
import {
  AddressesQuery,
  CreateAddressQuery,
} from "../../../../gql/AddressQueries";
import { GqlHookResp } from "../../../../lib/GqlHookResp";
import AddressPres from "./AddressPres";

export interface INewAddressProps {
  visible: boolean;
  goCancel(): void;
  onSuccess(addr: IAddress): void;
}

const blankAddress = (): IAddress => {
  return { firstName: "", lastName: "" };
};

const NewAddress = (props: INewAddressProps): JSX.Element => {
  const { visible, goCancel, onSuccess } = props;

  const onCompleted = (response: any) => {
    const { createAddress } = response;
    onSuccess(createAddress);
  };

  const mutationProps = {
    onCompleted,
    refetchQueries: [{ query: AddressesQuery }],
    onError: GqlHookResp.defaultErrorHandler,
  };
  const [mutate, rawMutResponse] = useMutation(
    CreateAddressQuery,
    mutationProps
  );
  const mutResp = GqlHookResp.fromRawResponse(rawMutResponse);
  if (mutResp.canHandle()) {
    return mutResp.handle();
  }

  const saveFn = (variables: any) => {
    mutate({ variables });
  };

  if (!visible) {
    return <span />;
  }

  return (
    <AddressPres address={blankAddress()} onSave={saveFn} goCancel={goCancel} />
  );
};

export default NewAddress;
