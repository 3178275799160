import { IGenericHash, IRefetchQuery, IWithNavProps } from "../../types/AppWeb";
import React from "react";
import {
  DeleteShipmentQuery,
  JobWithShipmentsQuery,
} from "../../gql/ShipmentQueries";
import { editShipmentForJobPg, newShipmentForJobPg } from "../../lib/UrlHelper";
import { EGqlFormFieldType } from "../../types/AppEnums";
import Crumbs from "../components/Crumbs";
import GqlList from "../meta/GqlList";
import GqlListRow from "../meta/GqlListRow";

const listSpecs = {
  display: {
    goToItem: (rowData: IGenericHash): string => {
      const { jobId, id: shipmentId } = rowData;
      return editShipmentForJobPg(jobId, shipmentId);
    },
    rowComponent: GqlListRow,
  },
  fields: [
    {
      key: "shippingAddress",
      label: "Destination",
      type: EGqlFormFieldType.Address,
    },
    {
      key: "method",
      label: "Method",
      type: EGqlFormFieldType.Text,
    },
  ],
  gql: {
    buildDeletionVariables: (rowData: IGenericHash): IGenericHash => ({
      id: rowData.id,
    }),
    buildRefetchQueries: (rowData: IGenericHash): IRefetchQuery[] => [
      { query: JobWithShipmentsQuery, variables: { id: rowData.jobId } },
    ],
    deleteItemQuery: DeleteShipmentQuery,
    extractListData: (qryData: IGenericHash): IGenericHash[] => {
      if (qryData && qryData.job && qryData.job.shipments) {
        return qryData.job.shipments;
      }
      return [];
    },
    listQuery: JobWithShipmentsQuery,
  },
  model: {
    name: "shipment",
  },
};

const ShipmentListTitle = "Shipments";

const ShipmentList = (props: IWithNavProps): JSX.Element => {
  const id = props.match.params.id;
  return (
    <div className="o4-page-without-sidebar shipments-page">
      <Crumbs location={props.location} />

      <h1>{ShipmentListTitle}</h1>
      <GqlList
        listSpecs={listSpecs}
        listVariables={{ id }}
        newFormPage={newShipmentForJobPg(id)}
      />
    </div>
  );
};

export { ShipmentListTitle };

export default ShipmentList;
