import {
  AppConf,
  logLevelDebug,
  logLevelError,
  logLevelInfo,
  logLevelWarn,
} from "../../conf/AppConf";

export class Konsole {
  // tslint:disable-next-line
  public static info(...args: any): void {
    if (AppConf.logLevel <= logLevelInfo) {
      console.log(...args);
    }
  }

  // tslint:disable-next-line
  public static debug(...args: any): void {
    if (AppConf.logLevel <= logLevelDebug) {
      console.log(...args);
    }
  }

  // tslint:disable-next-line
  public static error(...args: any): void {
    if (AppConf.logLevel <= logLevelError) {
      console.error(...args);
    }
  }

  // tslint:disable-next-line
  public static warn(...args: any): void {
    if (AppConf.logLevel <= logLevelWarn) {
      console.warn(...args);
    }
  }
}
