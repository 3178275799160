import { IGqlFormFieldSpec } from "../../../types/GqlForm";
import { IGenericHash } from "../../../types/AppWeb";

/**
 * Merge the current (incomplete) & initial state of the GqlForm, return the current form state
 */
export const getDefaultFieldValue = (
  spec: IGqlFormFieldSpec,
  formData: IGenericHash,
  initialData: IGenericHash
): IGenericHash | null => {
  const { key, dataKey } = spec;
  const formDataKey = dataKey || key;
  const possibleFormState = formData && formData[formDataKey];
  const possibleInitState = initialData && initialData[formDataKey];

  // JS quirk (return blank string using 'if'): '' || 'hello' ===
  if (possibleFormState === "") {
    return possibleFormState;
  }

  // Prefer formData > initialData > blank
  return possibleFormState || possibleInitState || null;
};
