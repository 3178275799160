// src/react-auth0-spa.js
import React, { useState, useEffect, useContext } from "react";
import createAuth0Client from "@auth0/auth0-spa-js";
import AppHistory from "../conf/AppHistory";
import { Konsole } from "./dev/Konsole";

const onRedirectCallback = (appState: any): any => {
  AppHistory.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

export const AUTH0_CONTEXT_DEFAULT_VALUE = {};
export const AUTH0_IS_AUTHENTICATED_INITIAL = false;
export const AUTH0_USER_INITIAL = undefined;
export const AUTH0_CLIENT_INITIAL = undefined;
export const AUTH0_BUNDLE_INITIAL = undefined;
export const AUTH0_LOADING_INITIAL = true;
export const AUTH0_POPUP_OPEN_INITIAL = false;

const Auth0Context = React.createContext(AUTH0_CONTEXT_DEFAULT_VALUE);

export const useAuth0 = (): any => useContext(Auth0Context);

export const Auth0Provider = ({
  children,
  domain,
  clientId,
  redirectUri,
}: any) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    AUTH0_IS_AUTHENTICATED_INITIAL
  );
  const [user, setUser] = useState(AUTH0_USER_INITIAL);
  const [auth0Client, setAuth0Client] = useState(AUTH0_CLIENT_INITIAL);
  const [authBundle, setAuthBundle] = useState(AUTH0_BUNDLE_INITIAL);
  const [loading, setLoading] = useState(AUTH0_LOADING_INITIAL);
  const [popupOpen, setPopupOpen] = useState(AUTH0_POPUP_OPEN_INITIAL);

  const auth0ClientOpts = {
    domain,
    client_id: clientId,
    redirect_uri: redirectUri,
  };

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(auth0ClientOpts);
      setAuth0Client(auth0FromHook as any);

      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        setUser(user as any);

        const newAuthBundle = await auth0FromHook.getIdTokenClaims();
        // @ts-ignore
        setAuthBundle(newAuthBundle);
      }

      setLoading(false);
    };
    initAuth0();

    // Note: intentionally exclude auth0ClientOpts to avoid an infinite render loop
  }, [onRedirectCallback]); // eslint-disable-line react-hooks/exhaustive-deps

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      // @ts-ignore
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      Konsole.error(error);
    } finally {
      setPopupOpen(false);
    }
    // @ts-ignore
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    // @ts-ignore
    await auth0Client.handleRedirectCallback();
    // @ts-ignore
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
    // @ts-ignore
    const newAuthBundle = await auth0Client.getIdTokenClaims();
    setAuthBundle(newAuthBundle);
  };
  return (
    <Auth0Context.Provider
      // @ts-ignore
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        authBundle,
        loginWithPopup,
        handleRedirectCallback,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        getIdTokenClaims: (...p: any) => auth0Client.getIdTokenClaims(...p),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        loginWithRedirect: (...p: any) => auth0Client.loginWithRedirect(...p),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        getTokenSilently: (...p: any) => auth0Client.getTokenSilently(...p),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        getTokenWithPopup: (...p: any) => auth0Client.getTokenWithPopup(...p),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        logout: (...p: any) => auth0Client.logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
