import AppHistory from "../conf/AppHistory";

// Goes to the page
export const navTo = (path: string): void => {
  AppHistory.push(path);
};

// Creates a function to go to a page
export const navFnFor = (path: string) => (): void => {
  navTo(path);
};

export const itemsForJobPg = (jobId: string): string => `/jobs/${jobId}/items`;
export const newItemForJobPg = (jobId: string): string =>
  `/jobs/${jobId}/items/new`;
export const editItemForJobPg = (jobId: string, itemId: string): string =>
  `/jobs/${jobId}/items/${itemId}`;

export const shipmentsForJobPg = (jobId: string): string =>
  `/jobs/${jobId}/shipments`;
export const editShipmentForJobPg = (
  jobId: string,
  shipmentId: string
): string => `/jobs/${jobId}/shipments/${shipmentId}`;
export const newShipmentForJobPg = (jobId: string): string =>
  `/jobs/${jobId}/shipments/new`;

export const jobsListPg = (): string => "/jobs";
export const editJobPg = (jobId: string): string => `/jobs/${jobId}`;
export const newJobPg = (): string => `/jobs/new`;

export const editUserSelfPg = (): string => "/user";
export const listUsersPg = (): string => "/users";
export const editUserPg = (id: string): string => `/user/${id}`;
export const newUserPg = (): string => `/user/new`;

export const subscriptionPg = (): string => "/subscription";
export const billingHistoryPg = (): string => "/billing-history";

export const listCardsPg = (): string => "/payment-methods";
export const newCardPg = (): string => "/payment-methods/new";

export const editOrganizationPg = (): string => "/organization";
export const editInvoicesPg = (): string => "/invoices";
