import { gql } from "@apollo/client";

export const AddressesQuery = gql`
  {
    addresses {
      id
      firstName
      lastName
      insertedAt
      updatedAt
    }
  }
`;

export const CreateAddressQuery = gql`
  mutation createAddress($firstName: String!, $lastName: String!) {
    createAddress(firstName: $firstName, lastName: $lastName) {
      id
      firstName
      lastName
      insertedAt
      updatedAt
    }
  }
`;

export const UpdateAddressQuery = gql`
  mutation updateAddress(
    $id: String!
    $firstName: String!
    $lastName: String!
  ) {
    updateAddress(id: $id, firstName: $firstName, lastName: $lastName) {
      id
      firstName
      lastName
      insertedAt
      updatedAt
    }
  }
`;

export const DeleteAddressQuery = gql`
  mutation deleteAddress($id: String!) {
    deleteAddress(id: $id) {
      id
      firstName
      lastName
    }
  }
`;
