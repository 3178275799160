import React from "react";
import Sidebar, { AccountSidebarLinks } from "../components/Sidebar";
import { gql } from "@apollo/client";
import { ErrorBoundary } from "../meta/ErrorBoundary";
import { newCardPg } from "../../lib/UrlHelper";
import { IGenericHash, IRefetchQuery } from "../../types/AppWeb";
import GqlListRow from "../meta/GqlListRow";
import { EGqlFormFieldType } from "../../types/AppEnums";
import GqlList from "../meta/GqlList";

const CardListTitle = "Payment Methods";

export const ListCardsQuery = gql`
  {
    listCards {
      brand
      last_4
      country
      strid
      cvcCheck
      expMonth
      expYear
      funding
      name
    }
  }
`;

export const DeleteCardQuery = gql`
  mutation deleteCard($strid: String!) {
    deleteCard(strid: $strid) {
      id
      brand
      last_4
      country
      strid
      cvcCheck
      expMonth
      expYear
      funding
      name
    }
  }
`;

const listSpecs = {
  display: {
    rowComponent: GqlListRow,
  },
  fields: [
    {
      key: "brand",
      label: "Brand",
      type: EGqlFormFieldType.Text,
    },
    {
      key: "last_4",
      label: "Last 4",
      type: EGqlFormFieldType.Text,
    },
    {
      key: "expMonth",
      label: "Exp Month",
      type: EGqlFormFieldType.Text,
    },
    {
      key: "expYear",
      label: "Exp Year",
      type: EGqlFormFieldType.Text,
    },
    {
      key: "name",
      label: "Name",
      type: EGqlFormFieldType.Text,
    },
  ],
  gql: {
    buildDeletionVariables: (rowData: IGenericHash): IGenericHash => ({
      strid: rowData.strid,
    }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    buildRefetchQueries: (rowData: IGenericHash): IRefetchQuery[] => [
      { query: ListCardsQuery },
    ],
    deleteItemQuery: DeleteCardQuery,
    extractListData: (qryData: IGenericHash): IGenericHash[] => {
      if (qryData && qryData.listCards) {
        return qryData.listCards;
      }
      return [];
    },
    listQuery: ListCardsQuery,
  },
  model: {
    name: "cards",
  },
};

const CardList = (): JSX.Element => {
  return (
    <div className="o4-layout-page payment-methods-page">
      <Sidebar
        links={AccountSidebarLinks}
        activeKey="acct-sidebar-payment-methods"
      />

      <div className="o4-layout-main">
        <h1>{CardListTitle}</h1>

        <p>
          This is a Stripe page - it is not functional because I am not
          supporting it.
        </p>

        <ErrorBoundary>
          <GqlList
            listSpecs={listSpecs}
            listVariables={{}}
            newFormPage={newCardPg()}
          />
        </ErrorBoundary>
      </div>
    </div>
  );
};

export { CardListTitle };

export default CardList;
