import * as Sentry from "@sentry/browser";
import { AbstractConf } from "../conf/AppConf";
import { AppConf } from "../conf/AppConf";
import { Konsole } from "./dev/Konsole";

export class BrowserJs {
  public static redirect = (url: string): void => {
    window.location.replace(url);
  };

  // React using regular JS - a <Redirect> will not actually leave React
  public static redirect404 = (): void => {
    if (AbstractConf.isStage) {
      BrowserJs.redirect("/error/404");
    } else if (AbstractConf.isDev) {
      BrowserJs.redirect("/error-404.html");
    }
  };

  // Override alert logic b/c vendor libraries can sometimes be nosy
  public static stubWindowAlert = (window: any): void => {
    window.alert = BrowserJs.alertOverride;
  };

  public static setTitle = (title: string): void => {
    document.title = title;
  };

  private static alertOverride = (...args: any[]): void => {
    Konsole.warn("Alert:", args); // tslint:disable-line
    if (AppConf.conf.sentryIoDsn) {
      const argsAsString = JSON.stringify(args);
      Sentry.captureMessage(argsAsString);
    }
  };
}
