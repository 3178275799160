import { TextField } from "@material-ui/core";
import _isNil from "lodash/isNil";
import React, { ChangeEvent, ReactText } from "react";
import Nothing from "../Nothing";

interface IFormInputProps {
  disabled?: boolean;
  id: string;
  label: string;
  required?: boolean;
  value: string | ReactText | null;
  visible?: boolean;
  onChange?(event: ChangeEvent<HTMLInputElement>): void;
}

// Basically identical to TextInput, except the <TextField> has type='password'
const PasswordInput = (props: IFormInputProps): JSX.Element => {
  const { onChange, label, id, required, value, disabled, visible } = props;

  if (!_isNil(visible) && !visible) {
    return <Nothing />;
  }

  const normalValue = value ? value.toString() : "";

  return (
    <div className="o4-input o4-input-password-input">
      <TextField
        id={id}
        label={label}
        value={normalValue}
        onChange={onChange ? onChange : undefined}
        disabled={disabled}
        required={required}
        variant="filled"
        type="password"
        className="o-mui-text-field-root"
      />
    </div>
  );
};

export default PasswordInput;
