import { gql } from "@apollo/client";

export const OrganizationQuery = gql`
  {
    organization {
      id
      name
    }
  }
`;

export const UpdateOrganizationQuery = gql`
  mutation updateOrganization($name: String, $paymentMethodStrid: String) {
    updateOrganization(name: $name, paymentMethodStrid: $paymentMethodStrid) {
      id
      name
      paymentMethodStrid
    }
  }
`;
