import { IGqlFormFieldSpec, IGqlFormSpecs } from "../../types/GqlForm";
import { IGenericHash } from "../../types/AppWeb";
import React, { Component } from "react";
import DateFormatter from "../../lib/formatters/DateFormatter";
import { noopNoArgFn } from "../../lib/Noop";
import { EGqlFormFieldType, EGqlFormPageType } from "../../types/AppEnums";
import { AlwaysThrowError, shouldThrowForTests } from "./AlwaysThrowError";
import { FieldTypes } from "./fieldTypes/FieldTypes";
import Button from "@material-ui/core/Button";

interface IDynamicFormProps {
  throwForTests?: boolean;
  formSpecs: IGqlFormSpecs;
  initialData: IGenericHash;
  saveFn(formData: IGenericHash, initialData: IGenericHash): void;
}

interface IDynamicFormState {
  formData: IGenericHash;
  fields: IGqlFormSpecs;
  initialData: IGenericHash;
  [methodNames: string]: any;
}

// todo: we should capitalize this properly, instead of something like 'setorgName'
const buildChangeFnName = (spec: IGqlFormFieldSpec): string => `set${spec.key}`;

const getFormChangeFn = (spec: IGqlFormFieldSpec, origThis: DynamicForm) => {
  const FieldType = FieldTypes[spec.type] as any;
  if (FieldType) {
    return FieldType.buildChangeFn(spec, origThis);
  }
  throw new Error("Field type not recognized");
};

const buildCommonProps = (spec: IGqlFormFieldSpec): IGenericHash => {
  const { key, label, required, disabled } = spec;
  return {
    disabled: disabled || false,
    id: key,
    key,
    label,
    required: required || false,
  };
};

class DynamicForm extends Component<IDynamicFormProps, IDynamicFormState> {
  public componentDidMount = (): void => {
    this.setState({ formData: {} });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public componentDidUpdate(prevProps: any): void {
    const { formSpecs } = this.props;
    if (!this.state) {
      return;
    }
    let newState = null as any;

    // Initialize state.fields
    if (this.state.formSpecs === undefined && this.props.formSpecs) {
      newState = { formSpecs };

      // Define input handler if non exist
      formSpecs.fields.forEach((spec: any) => {
        const methodName = buildChangeFnName(spec);
        if (!this.state[methodName]) {
          newState[methodName] = getFormChangeFn(spec, this);
        }
      }, this);
    }

    // Initialize state.initialData
    if (this.state.initialData === undefined && this.props.initialData) {
      newState = newState || {};
      newState.initialData = this.props.initialData;
      newState.formData = newState.formData || {};

      // Initialize formData as needed
      const mergedFormSpecs =
        (this.state && this.state.formSpecs) ||
        (newState && newState.formSpecs);
      const fields = mergedFormSpecs && mergedFormSpecs.fields;
      if (fields && formSpecs.gql) {
        const gqlPageType = formSpecs.gql.pageType;

        // Initialize 'id' formData for everything but create ('update', 'delete')
        if (gqlPageType !== EGqlFormPageType.Create) {
          newState.formData.id = this.props.initialData.id;
        }

        if (gqlPageType === EGqlFormPageType.Create) {
          fields.forEach((field: any) => {
            // todo this code should be pushed into the specific component
            if (field.type === EGqlFormFieldType.Datetime) {
              newState.formData[field.key] = DateFormatter.dateToIsoString(
                new Date()
              );
            }
          });
        }
      }
    }

    if (newState !== null) {
      this.setState(newState);
    }
  }

  public render = (): JSX.Element => {
    const { formSpecs, throwForTests } = this.props;
    const { fields, display } = formSpecs;
    const formData = this.state && this.state.formData;
    const initialData = this.state && this.state.initialData;
    const showButton = display === undefined || !display.readonly;

    return (
      <div className="o4-aligned-form" role="table">
        {shouldThrowForTests(throwForTests) && <AlwaysThrowError />}

        {fields &&
          fields.map((spec: any) => {
            const { type } = spec;
            const fieldType = FieldTypes[type];

            return React.createElement(fieldType.componentClass, {
              ...buildCommonProps(spec),
              onChange: this.getChangeFnFromSpec(spec),
              setStateForChild: this.setStateForChild,
              value: fieldType.toValueFn(spec, formData, initialData),
            });
          }, this)}

        <br />
        {showButton && (
          <Button variant="contained" color="primary" onClick={this.save}>
            {DynamicFormSaveButtonText}
          </Button>
        )}
      </div>
    );
  };

  private getChangeFnFromSpec = (spec: any): any => {
    const methodName = buildChangeFnName(spec);
    return this.state && this.state[methodName]
      ? this.state[methodName]
      : noopNoArgFn;
  };

  private setStateForChild = (newState: any): void => {
    const normalNewState = {
      formData: { ...this.state.formData, ...newState },
    };
    this.setState(normalNewState);
  };

  private readonly save = (): void => {
    const { formData, initialData } = this.state;
    const { saveFn } = this.props;
    saveFn(formData, initialData);
  };
}

export default DynamicForm;
export const DynamicFormSaveButtonText = "Save";
