import React from "react";
import { AbstractConf } from "../../conf/AppConf";

const AlwaysThrowErrorText =
  "Manually triggered by AlwaysThrowError for development purposes";

const AlwaysThrowErrorRegex = new RegExp(AlwaysThrowErrorText);

const isSafeToThrowForTest = (): any => {
  return AbstractConf.isTest || AbstractConf.isDev;
};

const shouldThrowForTests = (throwForTests: boolean | undefined) => {
  return !!throwForTests && isSafeToThrowForTest();
};

// Useful for exercising ErrorBoundary during development; just inject it in a render and see the world burn
export const AlwaysThrowError = (): JSX.Element => {
  if (isSafeToThrowForTest()) {
    throw new Error(AlwaysThrowErrorText);
  }
  return <></>;
};

export { AlwaysThrowErrorText, shouldThrowForTests, AlwaysThrowErrorRegex };
