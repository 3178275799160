import React from "react";
import { Link } from "react-router-dom";
import { ISidebarLink } from "../../types/Sidebar";
import {
  subscriptionPg,
  editInvoicesPg,
  editOrganizationPg,
  editUserSelfPg,
  listCardsPg,
  listUsersPg,
  billingHistoryPg,
} from "../../lib/UrlHelper";

export const AccountSidebarLinks: ISidebarLink[] = [
  {
    key: "acct-sidebar-billing-history",
    path: billingHistoryPg(),
    text: "Billing History",
  },
  {
    key: "acct-sidebar-subscription",
    path: subscriptionPg(),
    text: "Subscription",
  },
  {
    key: "acct-sidebar-invoices",
    path: editInvoicesPg(),
    text: "Invoices",
  },
  {
    key: "acct-sidebar-organization",
    path: editOrganizationPg(),
    text: "Organization",
  },
  {
    key: "acct-sidebar-payment-methods",
    path: listCardsPg(),
    text: "Payment Methods",
  },
  {
    key: "acct-sidebar-users",
    path: listUsersPg(),
    text: "Team",
  },
  {
    key: "acct-sidebar-user",
    path: editUserSelfPg(),
    text: "User",
  },
];

export interface ISidebarProps {
  links: ISidebarLink[];
  activeKey?: string;
}

const isLinksInvalid = (links: any) => {
  if (!Array.isArray(links)) {
    return true;
  }

  const badElements = links.filter((link: any) => {
    if (typeof link !== typeof {}) {
      return false;
    }
    return (
      typeof link.key !== "string" ||
      typeof link.path !== "string" ||
      typeof link.text !== "string"
    );
  });

  return badElements.length > 0;
};

const Sidebar = (props: ISidebarProps): JSX.Element => {
  const { links, activeKey } = props;

  if (isLinksInvalid(links)) {
    return (
      <div className="o4-layout-sidebar o4-sidebar-error">
        <p>There was a problem rendering the sidebar.</p>
      </div>
    );
  }

  return (
    <div className="o4-layout-sidebar">
      {links &&
        links.map((link: any) => {
          const { path, text, key } = link;
          let linkClasses = "o4-sidebar-item";
          if (activeKey === key) {
            linkClasses += " o4-sidebar-item-active";
          }

          return (
            <div className={linkClasses} key={key}>
              <Link to={path} className="o4-sidebar-link">
                {text}
              </Link>
            </div>
          );
        })}
    </div>
  );
};

export default Sidebar;
