import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Snackbar,
} from "@material-ui/core";
import { SnackbarAutoHideDuration } from "../../conf/AppConf";
import { Alert } from "@material-ui/lab";
import { Error as ErrorIcon } from "@material-ui/icons";
import React from "react";
import { buildHideAlertHandler } from "./gqlForm/SnackbarAlertHelpers";

export interface IGqlErrorDisplay {
  alert: any;
  setAlert: any;
  userErrors: any;
  setUserErrors: any;
}

export const GqlErrorDisplay = (props: IGqlErrorDisplay): JSX.Element => {
  const { alert, setAlert, userErrors } = props;
  const hideAlert = buildHideAlertHandler(alert, setAlert);

  return (
    <>
      <Snackbar
        open={alert.isOpen}
        autoHideDuration={SnackbarAutoHideDuration}
        onClose={hideAlert}
      >
        <Alert severity={alert.severity} variant="filled" onClose={hideAlert}>
          {alert.message}
        </Alert>
      </Snackbar>
      {userErrors && userErrors.length > 0 && (
        <div className="o4-error-container">
          <List disablePadding={true}>
            {userErrors.map((userError: any, idx: number) => {
              return (
                <ListItem alignItems="center" key={idx}>
                  <ListItemIcon className="o4-error-listItemIcon">
                    <ErrorIcon fontSize="small" className="o4-error-icon" />
                  </ListItemIcon>
                  <ListItemText primary={userError} />
                </ListItem>
              );
            })}
          </List>
        </div>
      )}
    </>
  );
};
