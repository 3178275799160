import { Button, TextField } from "@material-ui/core";
import _isNil from "lodash/isNil";
import { IAddress } from "../../../types/AppWeb";
import React, { Component } from "react";
import Nothing from "../Nothing";
import AddressDialog from "./FormAddress/AddressDialog";
import "./AddressSelector.scss";

export interface IAddressSelectorProps {
  disabled?: boolean;
  id: string;
  label: string;
  required?: boolean;
  value: string;
  visible?: boolean;
  onChange?(addr: IAddress): void;
}

class AddressSelector extends Component<IAddressSelectorProps, any> {
  constructor(args: any) {
    super(args);
    this.state = { addressDialogVisible: false };
  }

  public render = (): JSX.Element => {
    const { addressDialogVisible } = this.state;

    // todo consider implementing 'required' prop
    const { label, id, value, disabled, visible } = this.props;

    if (!_isNil(visible) && !visible) {
      return <Nothing />;
    }

    return (
      <div className="o4-input o4-input-address">
        <AddressDialog
          isOpen={addressDialogVisible}
          onClose={this.closeAddressDialog}
          onSelection={this.onAddressSelection}
        />
        <TextField
          id={id}
          label={label}
          value={value}
          disabled={true}
          variant="filled"
          className="o4-input-address-text"
        />
        {!disabled && (
          <Button
            onClick={this.showAddressDialog}
            variant="contained"
            color="primary"
            className="o4-input-address-button"
          >
            Select
          </Button>
        )}
      </div>
    );
  };

  public closeAddressDialog = (): void => {
    this.setState({ addressDialogVisible: false });
  };

  public onAddressSelection = (address: IAddress): void => {
    const { onChange } = this.props;
    this.setState({ addressDialogVisible: false });
    if (onChange) {
      onChange(address);
    }
  };

  private readonly showAddressDialog = (): void => {
    this.setState({
      ...this.state,
      addressDialogVisible: true,
    });
  };
}

export default AddressSelector;
