import React from "react";
import Sidebar, { AccountSidebarLinks } from "../components/Sidebar";
import { gql, useQuery } from "@apollo/client";
import { GqlHookResp } from "../../lib/GqlHookResp";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { Paper } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import _isEmpty from "lodash/isEmpty";
import { ErrorBoundary } from "../meta/ErrorBoundary";
import DateFormatter from "../../lib/formatters/DateFormatter";

const BillingHistoryTitle = "Billing History";

export const BillingHistoryQuery = gql`
  {
    listLogs {
      id
      userMessage
      message
      type
      insertedAt
    }
  }
`;

const BillingHistory = (): JSX.Element => {
  const rawQryResponse = useQuery(BillingHistoryQuery, {});
  const qryResp = GqlHookResp.fromRawResponse(rawQryResponse);

  if (qryResp.canHandle()) {
    return qryResp.handle();
  }

  console.log("BillingHistory:qryResp", qryResp);

  const logs = qryResp.data.listLogs.map((log: any) => {
    return {
      ...log,
      insertedAtExternal: DateFormatter.friendlyDateTime(log.insertedAt),
    };
  });

  const cols = [
    { external: "Time", internal: "insertedAtExternal" },
    { external: "Description", internal: "message" },
  ];

  const emptyBody = (
    <TableRow>
      <TableCell colSpan={cols.length}>&mdash; No entries &mdash;</TableCell>
    </TableRow>
  );

  const fullBody = logs.map((log: any) => {
    return (
      <TableRow key={`log-${log.id}`}>
        {cols.map((col) => {
          return (
            <TableCell key={`log-${log.id}-${col.internal}`}>
              {log[col.internal]}
            </TableCell>
          );
        })}
      </TableRow>
    );
  });

  return (
    <div className="o4-layout-page billing-history-page">
      <Sidebar
        links={AccountSidebarLinks}
        activeKey="acct-sidebar-billing-history"
      />

      <div className="o4-layout-main">
        <h1>{BillingHistoryTitle}</h1>

        <p>
          This is a Stripe page - it is not functional because I am not
          supporting it.
        </p>

        <ErrorBoundary>
          <TableContainer component={Paper}>
            <Table className="o4-table">
              <TableHead>
                <TableRow>
                  {cols.map((col) => {
                    return (
                      <TableCell key={`col-${col.internal}`}>
                        {col.external}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {_isEmpty(logs) && emptyBody}
                {!_isEmpty(logs) && fullBody}
              </TableBody>
            </Table>
          </TableContainer>
        </ErrorBoundary>
      </div>
    </div>
  );
};

export { BillingHistoryTitle };

export default BillingHistory;
