import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import React from "react";
import Sidebar, { AccountSidebarLinks } from "../components/Sidebar";
import { TableContainer } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import _isEmpty from "lodash/isEmpty";
import DateFormatter from "../../lib/formatters/DateFormatter";

const listInvoicesGql = gql`
  {
    listInvoices {
      id
      amountPaid
      amountRemaining
      hostedInvoiceUrl
      invoicePdf
      total
      periodStart
      periodEnd
      created
      subscription
      number
      status
    }
  }
`;

const InvoicesEditTitle = "Your Invoices";

const InvoicesEdit = (): JSX.Element => {
  const qryResponse = useQuery(listInvoicesGql, {});

  const invoicesData = qryResponse.data;

  const cols = ["Number", "Amount", "Created", "URL", "Status"];
  const emptyBody = (
    <TableRow>
      <TableCell colSpan={cols.length}>&mdash; No Invoices &mdash;</TableCell>
    </TableRow>
  );

  return (
    <div className="o4-layout-page account-page">
      <Sidebar links={AccountSidebarLinks} activeKey="acct-sidebar-invoices" />

      <div className="o4-layout-main">
        <h1>{InvoicesEditTitle}</h1>

        <p>
          This is a Stripe page - it is not functional because I am not
          supporting it.
        </p>

        <TableContainer component={Paper}>
          <Table className="o4-table">
            <TableHead>
              <TableRow>
                {cols.map((col) => (
                  <TableCell key={`invoices-th-${col}`}>{col}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {_isEmpty(invoicesData) && emptyBody}
              {!_isEmpty(invoicesData) &&
                invoicesData.listInvoices.map((invoice: any) => {
                  return (
                    <TableRow key={`stripe-invoice-${invoice.id}`}>
                      <TableCell>{invoice.number}</TableCell>
                      <TableCell>${invoice.total}</TableCell>
                      <TableCell>
                        {DateFormatter.friendlyDateTime(invoice.created)}
                      </TableCell>
                      <TableCell>
                        <a href={invoice.invoicePdf}>PDF Invoice</a>
                      </TableCell>
                      <TableCell>{invoice.status}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export { InvoicesEditTitle };

export default InvoicesEdit;
