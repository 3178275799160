import React from "react";
import { CreateUserQuery, ListUsersGql } from "../../gql/UserQueries";
import { BrowserJs } from "../../lib/BrowserJs";
import { listUsersPg, navFnFor } from "../../lib/UrlHelper";
import { EGqlFormFieldType, EGqlFormPageType } from "../../types/AppEnums";
import Sidebar, { AccountSidebarLinks } from "../components/Sidebar";
import GqlForm from "../meta/GqlForm";

const formSpecs = {
  fields: [
    {
      key: "email",
      label: "Email",
      required: true,
      type: EGqlFormFieldType.Text,
    },
    {
      key: "password",
      label: "Password",
      required: true,
      type: EGqlFormFieldType.Password,
    },
    {
      key: "passwordConfirmation",
      label: "passwordConfirmation",
      required: true,
      type: EGqlFormFieldType.Password,
    },
  ],
  gql: {
    mutation: CreateUserQuery,
    pageType: EGqlFormPageType.Create,
  },
  model: {
    name: "user",
  },
};

const UserNewTitle = "New User";

const UserNew = (props: any): JSX.Element => {
  const { id } = props.match.params;
  const refetchQueries = [{ query: ListUsersGql }];

  BrowserJs.setTitle(UserNewTitle);

  return (
    <div className="o4-layout-page account-page">
      <Sidebar links={AccountSidebarLinks} activeKey="acct-sidebar-user" />

      <div className="o4-layout-main">
        <h1>New User</h1>

        <GqlForm
          formSpecs={formSpecs}
          queryVariables={{ id }}
          onMutationCompleted={navFnFor(listUsersPg())}
          refetchQueries={refetchQueries}
        />
      </div>
    </div>
  );
};

export { UserNewTitle };

export default UserNew;
