import { ApolloError, useMutation } from "@apollo/client";
import { Button, TableCell } from "@material-ui/core";
import { Konsole } from "../../lib/dev/Konsole";
import { IGqlListFieldSpec, IGqlListSpecs } from "../../types/GqlList";
import { IGenericHash } from "../../types/AppWeb";
import React from "react";
import AppHistory from "../../conf/AppHistory";
import { FieldTypes } from "./fieldTypes/FieldTypes";
import "./GqlListRow.scss";
import { buildMutationProps } from "./GqlHandlers";

export interface IGqlListRowProps {
  data: IGenericHash;
  listSpecs: IGqlListSpecs;
  errorDisplayOpts?: any;
  refetchQueries?: any;
  onMutationCompleted?: any;
  onMutationError: any;
}

const assertDatumToLabelFn = (type: string): void => {
  const fieldType = FieldTypes[type];
  if (typeof fieldType.datumToLabelFn !== "function") {
    throw new Error(`Unable to find datumToLabelFn for type '${type}'`);
  }
};

const GqlListRow = (props: IGqlListRowProps): JSX.Element => {
  const { data, listSpecs, errorDisplayOpts } = props;
  const { fields, display } = listSpecs;
  const { buildExtraActions } = display;
  const { deleteItemQuery, buildRefetchQueries, buildDeletionVariables } =
    listSpecs.gql;
  const refetchQueries = buildRefetchQueries(data);
  const deletionVariables = buildDeletionVariables(data);

  const onMutationError = (error: ApolloError) => {
    Konsole.error("GqlListRow:error", error);
  };

  // eslint-disable-next-line
  const [mutate, rawMutResponse] = useMutation(
    deleteItemQuery,
    buildMutationProps(
      {
        ...props,
        onMutationError,
        refetchQueries,
      },
      errorDisplayOpts
    )
  );
  // const mutResponse = GqlHookResp.fromRawResponse(rawMutResponse);

  const url = display.goToItem === undefined ? "" : display.goToItem(data);
  const goToItem = (): any => {
    AppHistory.push(url);
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const noopFn = (): any => {};
  const cellClickFn = display.goToItem === undefined ? noopFn : goToItem;

  const deleteFn = (): void => {
    mutate({ variables: deletionVariables });
  };

  return (
    <tr>
      {fields &&
        fields.map((fieldSpec: IGqlListFieldSpec, idx: number) => {
          const { type } = fieldSpec;
          const fieldType = FieldTypes[type];

          assertDatumToLabelFn(type);

          const text = fieldType.datumToLabelFn(data, fieldSpec);

          return (
            <TableCell onClick={cellClickFn} key={idx}>
              {text}
            </TableCell>
          );
        })}
      <TableCell className="o4-action-td">
        {buildExtraActions && buildExtraActions(data)}

        <Button onClick={deleteFn} variant="contained" color="primary">
          Delete
        </Button>
      </TableCell>
    </tr>
  );
};

export default GqlListRow;
