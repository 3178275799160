import React from "react";
import { BrowserJs } from "../../../lib/BrowserJs";

export const NotFoundText = "Page not found";

/**
 * Render this when no other route matches
 *
 *  - Ideally, the web server has handled the routes, and this component is never rendered
 *  - Don't <Redirect> to /error-404.html; instead, configure the webserver
 *  - Render <div/> briefly - necessary to prevent react from complaining
 *  - Render an empty div to prevent UI flickering, which looks glitchy
 */
export default function NotFound(): JSX.Element {
  BrowserJs.setTitle(NotFoundText);
  BrowserJs.redirect404();
  return <div>{NotFoundText}</div>;
}
