export enum EGqlFormFieldType {
  // Generic types
  Text = "text",
  Datetime = "datetime",
  Address = "address",
  Password = "password",

  // Specific types
  WorkAttributes = "workAttributes",
}

export enum EGqlFormPageType {
  Create = "create",
  Edit = "edit",
}
