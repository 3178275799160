/**
 * Use the CSS tab above to style your Element's container.
 */
import { CardElement } from "@stripe/react-stripe-js";
import React from "react";
import "./CardSection.scss";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      "::placeholder": {
        color: "#aab7c4",
      },
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSize: "16px",
      fontSmoothing: "antialiased",
      height: "auto",
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const CardSection = (): any => {
  return (
    <div className="CreditCardFields">
      <label>Card Details</label>
      <CardElement options={CARD_ELEMENT_OPTIONS} />
    </div>
  );
};

export default CardSection;
