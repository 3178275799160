import React from "react";
import Sidebar, { AccountSidebarLinks } from "../components/Sidebar";
import { gql, useMutation, useQuery } from "@apollo/client";
import { GqlHookResp } from "../../lib/GqlHookResp";
import DateFormatter from "../../lib/formatters/DateFormatter";
import Button from "@material-ui/core/Button";

const SubscriptionTitle = "Subscription";

const SubscriptionQuery = gql`
  {
    subscription {
      balance
      billingCycleEndsAt
      billingCycleStartsAt
      cancelAt
      canceledAt
      cancelAtPeriodEnd
      isFreeTrial
      status
      strid
    }
  }
`;

const ToggleSubscriptionMutation = gql`
  mutation toggleSubscriptionMutation($cancelAtPeriodEnd: String!) {
    toggleSubscription(cancelAtPeriodEnd: $cancelAtPeriodEnd) {
      result
    }
  }
`;

const SubscriptionShow = (): JSX.Element => {
  const rawQryResponse = useQuery(SubscriptionQuery, {});
  const qryResp = GqlHookResp.fromRawResponse(rawQryResponse);

  const [mutate, rawMutResponse] = useMutation(ToggleSubscriptionMutation, {
    refetchQueries: [{ query: SubscriptionQuery }],
  });
  const mutResp = GqlHookResp.fromRawResponse(rawMutResponse);

  if (qryResp.canHandle()) {
    return qryResp.handle();
  }
  if (mutResp.canHandle()) {
    return mutResp.handle();
  }

  return (
    <div className="o4-layout-page account-page">
      <Sidebar
        links={AccountSidebarLinks}
        activeKey="acct-sidebar-subscription"
      />

      <div className="o4-layout-main">
        <h1>{SubscriptionTitle}</h1>

        <p>
          This is a Stripe page - it is not functional because I am not
          supporting it.
        </p>

        {qryResp.data && renderSubscription(qryResp, mutate)}
        {!qryResp.data && renderBlankStatus()}
      </div>
    </div>
  );
};

const renderBlankStatus = (): JSX.Element => {
  return (
    <p>
      We&apos;re still setting up your account! If you refresh in a few minutes,
      your account will likely be ready and you will be able to find your
      billing details here.
    </p>
  );
};

const renderSubscription = (qryResp: any, mutate: any): JSX.Element => {
  const {
    balance,
    billingCycleEndsAt,
    billingCycleStartsAt,
    cancelAtPeriodEnd,
    isFreeTrial,
    strid,
  } = qryResp.data.subscription;

  const planName = isFreeTrial === "true" ? "Free Trial" : "Paid Plan";
  const startsAt = DateFormatter.friendlyDateTime(billingCycleStartsAt);
  const endsAt = DateFormatter.friendlyDateTime(billingCycleEndsAt);
  const balanceStr = `$${parseFloat(balance).toFixed(2)}`;

  const buildCancellationMutation = (cancelAtPeriodEnd: boolean) => () => {
    mutate({ variables: { cancelAtPeriodEnd: cancelAtPeriodEnd.toString() } });
  };

  return (
    <>
      <h2>Your Plan</h2>
      <p>{planName}</p>

      <h2>Billing Period</h2>
      <p>
        {startsAt} - {endsAt}
      </p>

      <h2>Estimated Amount</h2>
      <p>{balanceStr}</p>

      <h2>Subscription</h2>
      <p>{strid}</p>

      <h2>cancelAtPeriodEnd</h2>
      <p>{cancelAtPeriodEnd}</p>

      {cancelAtPeriodEnd === "true" && (
        <Button onClick={buildCancellationMutation(false)}>
          Restore Subscription
        </Button>
      )}
      {cancelAtPeriodEnd !== "true" && (
        <Button onClick={buildCancellationMutation(true)}>
          Cancel Subscription
        </Button>
      )}
    </>
  );
};

export { SubscriptionTitle };

export default SubscriptionShow;
