import { gql } from "@apollo/client";

export const NoopQuery = gql`
  {
    noopQuery {
      nothing
    }
  }
`;

export const NoopMutation = gql`
  mutation noopMutation($nada: String) {
    noopMutation(nada: $nada) {
      nada
    }
  }
`;
