import { TGqlFieldSpec } from "../../../types/FieldTypes";
import { IGenericHash } from "../../../types/AppWeb";
import { WorkAttributes } from "../../../lib/model/WorkAttributes";

export const workAttributesToLabel = (
  datum: IGenericHash,
  fieldSpec: TGqlFieldSpec
): string => {
  return WorkAttributes.generateDescription(datum[fieldSpec.key]);
};

// set val to the whole work item (it's currently workAttributes)
export const workAttributesToValue = (
  spec: TGqlFieldSpec,
  formData: IGenericHash,
  initialData: IGenericHash
): IGenericHash => {
  const useForm =
    formData !== null &&
    formData !== undefined &&
    typeof formData === "object" &&
    Object.keys(formData).length > 1 &&
    formData.workAttributes;

  const potentialResult = useForm ? formData : initialData;
  return potentialResult || {};
};
