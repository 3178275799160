import _isNil from "lodash/isNil";
import { IAddress } from "../../types/AppWeb";

export class AddressFormatter {
  public static fullName = (address: IAddress | undefined | null): string => {
    if (_isNil(address)) {
      return "----";
    }
    const { firstName, lastName } = address;
    return `${firstName} ${lastName}`;
  };
}

export default AddressFormatter;
