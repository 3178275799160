// Vendor libraries
import React from "react";
import ReactDOM from "react-dom";
import { AppConf } from "./conf/AppConf";
import AppHistory from "./conf/AppHistory";
import { BrowserJs } from "./lib/BrowserJs";
import { ErrorBoundary } from "./react/meta/ErrorBoundary";
import * as serviceWorker from "./vendor/js/serviceWorker";

import { Auth0Provider, useAuth0 } from "./lib/UseAuth0";
// import config from "./auth_config.json";

// ply libraries
import "./conf/SentryInit";
import App from "./react/App";

// ply css
import "./stylesheets/AttributeRow.scss";
import "./stylesheets/Decorators.scss";
import "./stylesheets/Imports.scss";
import "./stylesheets/LayoutPositioning.scss";
import "./stylesheets/LayoutSidebar.scss";

// Override alert logic b/c vendor libraries can sometimes be nosy
BrowserJs.stubWindowAlert(window);

// Initialize
// ReactDOM.render(<App />, document.getElementById("o4-root"));
ReactDOM.render(
  <ErrorBoundary withLeftMargin>
    <Auth0Provider
      domain={AppConf.conf.auth0Domain}
      clientId={AppConf.conf.auth0ClientId}
      redirectUri={AppConf.conf.auth0RedirectUri}
    >
      <App auth0Module={useAuth0} appHistory={AppHistory} />
    </Auth0Provider>
  </ErrorBoundary>,
  document.getElementById("o4-root")
);
serviceWorker.unregister(); // Register to work offline and load faster (https://bit.ly/CRA-PWA)
