import React from "react";

const ErrorBoundaryText =
  "Oops! Looks like something went wrong on the webpage.";

export class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.log("ErrorBoundary:error:", error);
    console.log("ErrorBoundary:errorInfo:", errorInfo);
  }

  render() {
    const { children, withLeftMargin } = this.props;

    const leftMarginCss = withLeftMargin ? { margin: "1em" } : undefined;

    if (this.state.hasError) {
      return <p style={leftMarginCss}>{ErrorBoundaryText}</p>;
    }

    return children;
  }
}

export { ErrorBoundaryText };
