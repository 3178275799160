// Declare constants up top for reusability

// Use Rails log levels: https://guides.rubyonrails.org/debugging_rails_applications.html
const logLevelSilent = 5;
const logLevelFatal = 4;
const logLevelError = 3;
const logLevelWarn = 2;
const logLevelInfo = 1;
const logLevelDebug = 0;

const StripePublishableKeyInDev = "pk_test_yMRQGo0AeNvYHkWQcIyUaMS700HU5wddt7";

// Conf: dev environment
const devConf = {
  /** Set timeout to be some very high value b/c otherwise the closed HTTP connection will cause Phoenix (cowboy) to
   * reset too quickly. Consider setting it to 10s if we don't need Phoenix debugging and want tighter response times.
   */
  apolloTimeoutMs: 10 * 60 * 1000, // 10 minutes, as ms
  auth0ClientId: "pxrt5lREfcKJMNllWkp1goarctmP96jK",
  auth0Domain: "ply.us.auth0.com",
  auth0RedirectUri: "http://localhost:3000",
  auth0LogoutUri: "http://localhost:3000",
  logLevel: logLevelDebug,
  numGqlRetries: 3,
  webDomain: "localhost:4000",
  webProtocol: "http",
  webWsProtocol: "ws",
  sentryIoDsn: undefined, // Not supported
  stripePublishableKey: StripePublishableKeyInDev,
};

// Conf: dev environment
const testConf = {
  apolloTimeoutMs: 200,
  auth0ClientId: "dkJwepI9XpYpaBVpZw1W8fSpwl1GN7Pl",
  auth0Domain: "ply.auth0.com",
  auth0RedirectUri: "http://localhost:3000",
  auth0LogoutUri: "http://localhost:3000",
  logLevel: logLevelError,
  numGqlRetries: 0,
  webDomain: "localhost:4000",
  webProtocol: "http",
  webWsProtocol: "ws",
  sentryIoDsn: undefined, // Not supported
  stripePublishableKey: StripePublishableKeyInDev,
};

// Conf: stage environment (web:gigalixir, ui:netlify)
const stageConf = {
  apolloTimeoutMs: 10000,
  auth0ClientId: "MBLAsNnlgsWCB4VPTMqPjSPWo3UrOoar",
  auth0Domain: "ply-stage.us.auth0.com",
  auth0RedirectUri: "https://stage.q23.me",
  auth0LogoutUri: "https://stage.q23.me",
  logLevel: logLevelInfo,
  numGqlRetries: 3,
  webDomain: "web.stage.q23.me",
  webProtocol: "https",
  webWsProtocol: "wss",
  sentryIoDsn: "https://45519e27148947578e097bc81059cfb3@sentry.io/1422718",
  stripePublishableKey: StripePublishableKeyInDev,
};

export class AbstractConf {
  public static buildConf(): AbstractConf {
    if (AbstractConf.isDev) {
      return new AbstractConf(devConf);
    }
    if (AbstractConf.isTest) {
      return new AbstractConf(testConf);
    }
    if (AbstractConf.isStage) {
      return new AbstractConf(stageConf);
    }
    throw new Error('We currently only support "dev" and "stage" envs');
  }

  public static get currentEnv(): string {
    if (process.env.NODE_ENV === "development") {
      return "dev";
    } else if (process.env.NODE_ENV === "test") {
      return "test";
    }
    return "stage"; // Force us to use stage for now; in the future, use env variables to build for multiple envs
  }

  public static get isDev(): boolean {
    return AbstractConf.currentEnv === "dev";
  }
  public static get isStage(): boolean {
    return AbstractConf.currentEnv === "stage";
  }
  public static get isProd(): boolean {
    return AbstractConf.currentEnv === "prod";
  }
  public static get isTest(): boolean {
    return AbstractConf.currentEnv === "test";
  }

  public readonly conf: any;

  constructor(conf: any) {
    if (!AbstractConf.isDev && !AbstractConf.isStage && !AbstractConf.isTest) {
      throw new Error(
        'We currently only support "dev", "test", and "stage" envs'
      );
    }
    this.conf = conf;
  }

  get sentryIoDsn(): string {
    if (AbstractConf.isStage) {
      return this.conf.sentryIoDsn;
    }
    throw new Error("Only set up for stage env right now");
  }

  get gqlUrl(): string {
    return `${this.conf.webProtocol}://${this.conf.webDomain}/gql`;
  }

  get socketUrl(): string {
    return `${this.conf.webWsProtocol}://${this.conf.webDomain}/socket`;
  }

  get logLevel(): number {
    return this.conf.logLevel;
  }

  get apolloTimeoutMs(): number {
    return this.conf.apolloTimeoutMs;
  }

  get numGqlRetries(): number {
    return this.conf.numGqlRetries;
  }
}

export const AppWs = {
  jobCreated: "job_created",
  jobDeleted: "job_deleted",
  jobUpdated: "job_updated",
  jobsTopic: "jobs:all",
};

const SnackbarAutoHideDuration = 5000;

export {
  logLevelDebug,
  logLevelInfo,
  logLevelWarn,
  logLevelError,
  logLevelFatal,
  logLevelSilent,
  SnackbarAutoHideDuration,
};

export const AppConf = AbstractConf.buildConf();
